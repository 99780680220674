import React, { useState, useEffect } from 'react'
import axios from "axios"
import Modal from 'react-modal'
import Cookies from 'js-cookie'
import { Box, Card, Typography, TextField, Button, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import "./css/pages.css"
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import toast, { Toaster } from 'react-hot-toast'
import { capitalizeValues } from '../utils/formatters'

const SecurityDeposit = () => {

  Modal.setAppElement(SecurityDeposit)
  Modal.setAppElement('#root')

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }

  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalIsOpen1, setIsOpen1] = useState(false)
  const [rules, setRules] = useState([])

  const [buttonMessage, setButtonMessage] = useState('')

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const openModal1 = () => {
    setIsOpen1(true)
  }

  const closeModal1 = () => {
    setIsOpen1(false)
    setDepositData({
      employee_id: '',
      deposit_amount: '',
      deposit_date: ''
    })
  }

  const [depositData, setDepositData] = useState({
    company_id: Cookies.get('userInfo'),
    employee_id: '',
    deposit_amount: '',
    deposit_date: ''
  })

  const [employeeList, setEmployeeList] = useState([])
  const [depositHistory, setDepositHistory] = useState([])
  const [filterArr, setFilterArr] = useState([])

  function filterFunc(e) {
    let temp = employeeList.filter((v, i) => {
      return v.employeename.toLowerCase().indexOf(e.toLowerCase()) !== -1
    })
    setFilterArr(temp)
  }

  function changeTimeRegion(dateString) {

    const utcDate = new Date(dateString)

    console.log(utcDate)

    const formatter = new Intl.DateTimeFormat('en-PK', {
      timeZone: 'Asia/Karachi',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      // hour: '2-digit',
      // minute: '2-digit',
      // second: '2-digit'
    })

    let formValue = formatter.format(utcDate)

    const year = formValue.slice(6, 10)
    const month = formValue.slice(3, 5)
    const day = formValue.slice(0, 2)

    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`

    return formattedDate

  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setDepositData({
      ...depositData,
      [name]: value,
    })
  }

  const addDeposit = async (e) => {
    e.preventDefault()
    if ((depositData.employee_id === "" || depositData.deposit_amount === "" || depositData.deposit_date === "")) {
      toast.error("All fields are required", {
        duration: 5000,
        position: 'bottom-right',
        style: {
          minWidth: '300px',
          minHeight: "50px"
        }
      })
    } else {
      try {
        setButtonMessage("Adding")
        const response = await axios.post('/api/addDeposit', {
          body: depositData
        })
        if (response.data.success) {
          setButtonMessage('')
          toast.success(response.data.message, {
            duration: 5000,
            position: 'bottom-right',
            style: {
              borderBottom: "3px solid #61D345",
              minWidth: '300px',
              minHeight: "50px"
            }
          })
          closeModal1()
          fetchEmployeeList()
        } else {
          setButtonMessage('')
          console.error('Failed to add deposit: ', response.data.error)
        }
      } catch (error) {
        setButtonMessage('')
        console.error('Error adding deposit: ', error)
      }
    }
  }

  const fetchEmployeeList = async () => {
    try {
      const response = await axios.post('/api/fetchEmployeeList', {
        id: Cookies.get('userInfo')
      })
      if (response.data.response) {
        setEmployeeList(capitalizeValues(response.data.data))
      }
      else {
        console.error('Failed to fetch employee list: ', response.data.error)
      }
    } catch (error) {
      console.error('Error fetching employee list: ', error)
    }
  }

  const fetchRules = async () => {
    let id = Cookies.get('eid')
    let userInfo = Cookies.get('userInfo')
    if (id) {
      try {
        const response = await axios.post('/api/fetchRules', {
          id: id,
          module: "frm_Deposit_List"
        })
        if (response.data.response) {
          setRules(response.data.data)
        }
        else {
          console.error('Failed to fetch rules: ', response.data.error)
        }
      } catch (error) {
        console.error('Error fetching rules: ', error)
      }
    }
    else {
      try {
        const response = await axios.post('/api/fetchRules', {
          userInfo: userInfo,
        })
        if (response.data.response) {
          setRules(response.data.data)
        }
        else {
          console.error('Failed to fetch rules: ', response.data.error)
        }
      } catch (error) {
        console.error('Error fetching rules: ', error)
      }
    }
  }

  useEffect(() => {
    fetchEmployeeList()
    fetchRules()
  }, [])

  async function funcDepositHistory(v) {
    setDepositHistory({})
    try {
      const response = await axios.post('/api/fetchDepositHistory', {
        id: v.employee_id
      })
      if (response.data.response) {
        setDepositHistory(capitalizeValues(response.data.data))
        setIsOpen(true)
      }
      else {
        console.error('Failed to fetch deposit history: ', response.data.error)
      }
    } catch (error) {
      console.error('Error fetching deposit history: ', error)
    }
  }

  return (
    <>
      {
        rules.length > 0
          ?
          (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1 || rules[0].Allow_View == 1)
            ?
            <Box sx={{ display: 'flex', flexDirection: "column", height: "auto", minWidth: 1, padding: 1 }}>
              <Box sx={{ display: { xs: 'block', sm: 'flex' }, justifyContent: "space-between", }}>
                <Box sx={{ display: 'flex' }}>
                  <Card sx={{
                    margin: 0.5, mr: 1, padding: 1, paddingTop: 1, display: "flex", justifyContent: "center", alignItems: "center",
                  }}><AccountCircleIcon fontSize="large" /></Card>
                  <Typography sx={{ mt: "auto", mb: "auto", fontSize: { xs: '1.5rem', sm: "2rem" } }}>Deposits</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1, mt: 1, marginBottom: { xs: '10px', sm: "0px" } }}>
                  <TextField
                    style={{ marginRight: 5 }}
                    onChange={(e) => filterFunc(e.target.value)}
                    label={`Search Employee`}
                    type="search"
                    size='small'
                    sx={{ width: { sx: "100%" }, }}
                  />
                  <Button
                    size="small" variant='outlined' sx={{ height: 40, width: 120 }}
                    onClick={() => openModal1()}
                    disabled={
                      rules.length > 0
                        ?
                        (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                          ?
                          false
                          :
                          rules[0].Allow_Add == 1
                            ?
                            false
                            :
                            true
                        :
                        true
                    }
                  >
                    Add Deposit
                  </Button>
                </Box>
              </Box>

              <table className="table table-bordered" id="table-main">
                <thead>
                  <tr>
                    <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>S. No</th>
                    <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Employee ID</th>
                    <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Name</th>
                    <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>CNIC</th>
                    <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Phone</th>
                    <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Designation</th>
                    <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Department</th>
                    <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Branch</th>
                    <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Hired Date</th>
                    <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Deposit</th>
                    <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>History</th>
                  </tr>
                </thead>
                {
                  filterArr.length
                    ?
                    filterArr.map((v2, i2) => {
                      return <>
                        <tbody>
                          <tr key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                            <th style={{ fontWeight: "bold", padding: 2, textAlign: "center", verticalAlign: "middle" }}>{(i2 + 1).toString().length < 2 ? "0" + (i2 + 1) : (i2 + 1)}</th>
                            <td style={{ fontWeight: "bold", padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.employee_id}</td>
                            <td style={{ fontWeight: "bold", padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.employeename}</td>
                            <td style={{ fontWeight: "bold", padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.employeecnic}</td>
                            <td style={{ fontWeight: "bold", padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.mobilenumber}</td>
                            <td style={{ fontWeight: "bold", padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.designation}</td>
                            <td style={{ fontWeight: "bold", padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.departmentName}</td>
                            <td style={{ fontWeight: "bold", padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.branch_name}</td>
                            <td style={{ fontWeight: "bold", padding: 2, textAlign: "left", verticalAlign: "middle" }}>{changeTimeRegion(v2.doa)}</td>
                            <td style={{ fontWeight: "bold", padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.total_security_deposit ? v2.total_security_deposit : 0}</td>
                            <td style={{ verticalAlign: "middle" }}>
                              <Button
                                disabled={v2.total_security_deposit ? false : true}
                                size="small" variant='outlined' sx={{ height: 25, marginRight: 1, width: 60 }}
                                onClick={() => funcDepositHistory(v2)}
                              >{"History"}</Button>
                            </td>
                          </tr>
                        </tbody>
                      </>
                    })
                    :
                    employeeList.map((v2, i2) => {
                      return <>
                        <tbody>
                          <tr key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                            <th style={{ fontWeight: "bold", padding: 2, textAlign: "center", verticalAlign: "middle" }}>{(i2 + 1).toString().length < 2 ? "0" + (i2 + 1) : (i2 + 1)}</th>
                            <td style={{ fontWeight: "bold", padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.employee_id}</td>
                            <td style={{ fontWeight: "bold", padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.employeename}</td>
                            <td style={{ fontWeight: "bold", padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.employeecnic}</td>
                            <td style={{ fontWeight: "bold", padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.mobilenumber}</td>
                            <td style={{ fontWeight: "bold", padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.designation}</td>
                            <td style={{ fontWeight: "bold", padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.departmentName}</td>
                            <td style={{ fontWeight: "bold", padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.branch_name}</td>
                            <td style={{ fontWeight: "bold", padding: 2, textAlign: "left", verticalAlign: "middle" }}>{changeTimeRegion(v2.doa)}</td>
                            <td style={{ fontWeight: "bold", padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.total_security_deposit ? v2.total_security_deposit : 0}</td>
                            <td style={{ verticalAlign: "middle" }}>
                              <Button
                                disabled={v2.total_security_deposit ? false : true}
                                size="small" variant='outlined' sx={{ height: 25, marginRight: 1, width: 60 }}
                                onClick={() => funcDepositHistory(v2)}
                              >{"History"}</Button>
                            </td>
                          </tr>
                        </tbody>
                      </>
                    })
                }
              </table>
              <table
                className="table"
                id="table-main-small">
                {
                  filterArr.length
                    ?
                    filterArr.map((v1, i1) => {
                      return <>
                        <tbody key={i1 + 1} style={{ backgroundColor: ((i1 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                          <tr key={i1 + 1}>
                            <td scope="col" id="mobile-style" >: {v1.employee_id}</td>
                            <td scope="col" rowSpan={3} id="mobile-style" style={{ textAlign: "right" }}>
                              <Button
                                disabled={v1.total_security_deposit ? false : true}
                                size="small" variant='outlined' sx={{ height: 30, width: 70, marginBottom: 1 }}
                                onClick={() => funcDepositHistory(v1)}
                              >{"History"}</Button>
                            </td>
                          </tr>
                          <tr key={i1 + 1}>
                            <td scope="col" id="mobile-style" >Name: {v1.employeename}</td>
                          </tr>
                          <tr key={i1 + 1}>
                            <td scope="col" id="mobile-style" >CNIC: {v1.employeecnic}</td>
                          </tr>
                          <tr key={i1 + 1}>
                            <td scope="col" id="mobile-style" >Phone: {v1.mobilenumber}</td>
                            <td scope="col" id="mobile-style" ></td>
                          </tr>
                          <tr key={i1 + 1}>
                            <td scope="col" id="mobile-style" >Designation {v1.designation}</td>
                            <td scope="col" id="mobile-style" ></td>
                          </tr>
                          <tr key={i1 + 1}>
                            <td scope="col" id="mobile-style" >Branch: {v1.branch_name}</td>
                            <td scope="col" id="mobile-style" ></td>
                          </tr>
                          <tr key={i1 + 1}>
                            <td scope="col" id="mobile-style" >Hired Date: {changeTimeRegion(v1.doa)}</td>
                            <td scope="col" id="mobile-style" ></td>
                          </tr>
                          <tr key={i1 + 1}>
                            <td scope="col" id="mobile-style" >Deposit: {v1.total_security_deposit ? v1.total_security_deposit : 0}</td>
                            <td scope="col" id="mobile-style" ></td>
                          </tr>
                        </tbody>
                        <hr style={{ margin: 5, padding: 0 }} />
                      </>
                    })
                    :
                    employeeList.map((v1, i1) => {
                      return <>
                        <tbody key={i1 + 1} style={{ backgroundColor: ((i1 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                          <tr key={i1 + 1}>
                            <td scope="col" id="mobile-style" >Employee ID: {v1.employee_id}</td>
                            <td scope="col" rowSpan={3} id="mobile-style" style={{ textAlign: "right" }}>
                              <Button
                                disabled={v1.total_security_deposit ? false : true}
                                size="small" variant='outlined' sx={{ height: 30, width: 70, marginBottom: 1 }}
                                onClick={() => funcDepositHistory(v1)}
                              >{"History"}</Button>
                            </td>
                          </tr>
                          <tr key={i1 + 1}>
                            <td scope="col" id="mobile-style" >Name: {v1.employeename}</td>
                          </tr>
                          <tr key={i1 + 1}>
                            <td scope="col" id="mobile-style" >CNIC: {v1.employeecnic}</td>
                          </tr>
                          <tr key={i1 + 1}>
                            <td scope="col" id="mobile-style" >Phone: {v1.mobilenumber}</td>
                            <td scope="col" id="mobile-style" ></td>
                          </tr>
                          <tr key={i1 + 1}>
                            <td scope="col" id="mobile-style" >Designation: {v1.designation}</td>
                            <td scope="col" id="mobile-style" ></td>
                          </tr>
                          <tr key={i1 + 1}>
                            <td scope="col" id="mobile-style" >Branch: {v1.branch_name}</td>
                            <td scope="col" id="mobile-style" ></td>
                          </tr>
                          <tr key={i1 + 1}>
                            <td scope="col" id="mobile-style" >Hired Date: {changeTimeRegion(v1.doa)}</td>
                            <td scope="col" id="mobile-style" ></td>
                          </tr>
                          <tr key={i1 + 1}>
                            <td scope="col" id="mobile-style" >Deposit: {v1.total_security_deposit ? v1.total_security_deposit : 0}</td>
                            <td scope="col" id="mobile-style" ></td>
                          </tr>
                        </tbody>
                        <hr style={{ margin: 5, padding: 0 }} />
                      </>
                    })
                }
              </table>
              <div>
                {/* //////////////////////////////////// */}
                <Box>
                  <Dialog
                    open={modalIsOpen1}
                    onClose={closeModal1}
                    PaperProps={{
                      component: 'form',
                      sx: {
                        width: { xs: '100%', sm: '70%' },
                      },
                    }}
                  >
                    <DialogTitle sx={{ fontSize: { xs: "2rem", md: "2.5rem" } }} >{`Add Deposit`}</DialogTitle>
                    <DialogContent sx={{
                      '& .MuiInputBase-input': { fontSize: '1.5rem' },
                      '& .MuiInputLabel-root': { fontSize: '1.25rem' },
                    }}>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <TextField autoFocus fullWidth variant="standard"
                          id="employee_id"
                          name="employee_id"
                          label="Employee Name | ID:"
                          value={depositData.employee_id}
                          onChange={handleInputChange}
                          select
                        >
                          {employeeList.map((v, i) => {
                            return <MenuItem key={i} value={v.employee_id}>{`${v.employeename} | ${v.employee_id}`}</MenuItem>
                          })}
                        </TextField>
                        <TextField fullWidth variant="standard"
                          id="deposit_amount"
                          type="number"
                          name="deposit_amount"
                          label="Deposit Amount:"
                          value={depositData.deposit_amount}
                          onChange={handleInputChange}
                        />
                        <TextField fullWidth variant="standard"
                          id="deposit_date"
                          name="deposit_date"
                          label="Date:"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={depositData.deposit_date}
                          onChange={handleInputChange}
                        />
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={closeModal1}
                        disabled={buttonMessage !== '' ? true : false}
                      >
                        Cancel & Close
                      </Button>
                      <Button
                        onClick={addDeposit}
                        disabled={buttonMessage !== '' ? true : false}
                      >
                        {buttonMessage !== '' ? buttonMessage : "Add"}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>




                {/* //////////////////////////////////// */}
              </div>

              <div>
                <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                  style={customStyles}
                >
                  <div>Deposit History</div>
                  <br />
                  <table>
                    {depositHistory.length > 0 && depositHistory.map((v, i) => {
                      return <tbody>
                        <tr>
                          <td>
                            Name:
                          </td>
                          <td>
                            {v.employeename}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Designation:
                          </td>
                          <td>
                            {v.designation}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Department:
                          </td>
                          <td>
                            {v.departmentName}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Deposit Amount:
                          </td>
                          <td>
                            {v.deposit_amount}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Deposit Date:
                          </td>
                          <td>
                            {v.deposit_date}
                          </td>
                        </tr>
                        <br />
                      </tbody>
                    })}
                  </table>
                  <Button
                    variant='outlined'
                    onClick={closeModal}>Close</Button>
                </Modal>
              </div>
              <Toaster />
            </Box>
            :
            <div style={{ padding: 10 }}>View disabled</div>
          :
          ""
      }
    </>
  )
}

export default SecurityDeposit