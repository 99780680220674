import React, { useState, useEffect } from 'react'
import axios from "axios"
import Cookies from 'js-cookie'
import { Box, Button, TextField, Card, Typography, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem } from "@mui/material"
import ThreePIcon from '@mui/icons-material/ThreeP'
import toast, { Toaster } from 'react-hot-toast'
import { capitalizeValues } from '../utils/formatters'

const Designation = () => {

    const [modalIsOpen1, setIsOpen1] = useState(false)
    const [filterArr, setFilterArr] = useState([])
    const [editDesignation, setEditDesignation] = useState({})
    const [modalIsOpen2, setIsOpen2] = useState(false)
    const [rules, setRules] = useState([])

    const [buttonMessage, setButtonMessage] = useState('')

    const openModal1 = () => {
        setIsOpen1(true)
    }

    const setupedit = (v) => {
        setEditDesignation(v)
        setIsOpen2(true)
    }

    function handledata(e) {
        setEditDesignation({ ...editDesignation, [e.target.name]: e.target.value })
    }

    const closeModal2 = () => {
        setIsOpen2(false)
    }

    const editDesignationSubmit = async (e) => {
        e.preventDefault()
        try {
            setButtonMessage("Submitting")
            const response = await axios.post('/api/editDesignationSubmit', {
                body: editDesignation
            })
            if (response.data.success) {
                setButtonMessage('')
                toast.success(response.data.message, {
                    duration: 5000,
                    position: 'bottom-right',
                    style: {
                        borderBottom: "3px solid #61D345",
                        minWidth: '300px',
                        minHeight: "50px"
                    }
                })
                fetchDesignationList()
                closeModal2()
            } else {
                setButtonMessage('')
                console.error('Failed to edit designation: ', response.data.error)
            }
        } catch (error) {
            setButtonMessage('')
            console.error('Error editing designation: ', error)
        }
    }

    async function deletefunc(v) {
        try {
            const response = await axios.post('/api/deleteDesignation', {
                body: v
            })
            if (response.data.response) {
                fetchDesignationList()
                toast.success("Successfully deleted", {
                    duration: 5000,
                    position: 'bottom-right',
                    style: {
                        borderBottom: "3px solid #61D345",
                        minWidth: '300px',
                        minHeight: "50px"
                    }
                })
            }
            else {
                console.error('Failed to delete designation: ', response.data.error)
            }
        } catch (error) {
            console.error('Error deleting designation: ', error)
        }
    }

    const closeModal1 = () => {
        setIsOpen1(false)
    }

    const [designationData, setDesignationData] = useState({
        company_id: Cookies.get('userInfo'),
        designation: '',
        designation_short: '',
        scale: '',
    })

    const [designationList, setDesignationList] = useState([])

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setDesignationData({
            ...designationData,
            [name]: value,
        })
    }

    const addDesignation = async (e) => {
        e.preventDefault()
        if (designationData.designation === "" || designationData.scale === "") {
            toast.error("All fields are required", {
                duration: 5000,
                position: 'bottom-right',
                style: {
                    minWidth: '300px',
                    minHeight: "50px"
                }
            })
        } else {
            try {
                setButtonMessage("Adding")
                const response = await axios.post('/api/addDesignation', {
                    body: designationData
                })
                if (response.data.success) {
                    setButtonMessage('')
                    toast.success(response.data.message, {
                        duration: 5000,
                        position: 'bottom-right',
                        style: {
                            borderBottom: "3px solid #61D345",
                            minWidth: '300px',
                            minHeight: "50px"
                        }
                    })
                    closeModal1()
                    setDesignationData({
                        ...designationData,
                        designation: '',
                        designation_short: '',
                        scale: '',
                    })
                    fetchDesignationList()
                } else {
                    setButtonMessage('')
                    console.error('Failed to add designation: ', response.data.error)
                }
            } catch (error) {
                setButtonMessage('')
                console.error('Error adding designation: ', error)
            }
        }

    }

    const fetchDesignationList = async () => {
        try {
            const response = await axios.post('/api/fetchDesignationList', {
                id: Cookies.get('userInfo')
            })
            if (response.data.response) {
                setDesignationList(capitalizeValues(response.data.data))
            }
            else {
                console.error('Failed to fetch designation list: ', response.data.error)
            }
        } catch (error) {
            console.error('Error fetching designation list: ', error)
        }
    }

    const fetchRules = async () => {
        let id = Cookies.get('eid')
        let userInfo = Cookies.get('userInfo')
        if (id) {
            try {
                const response = await axios.post('/api/fetchRules', {
                    id: id,
                    module: "frm_Designation_List"
                })
                if (response.data.response) {
                    setRules(response.data.data)
                }
                else {
                    console.error('Failed to fetch rules: ', response.data.error)
                }
            } catch (error) {
                console.error('Error fetching rules: ', error)
            }
        }
        else {
            try {
                const response = await axios.post('/api/fetchRules', {
                    userInfo: userInfo,
                })
                if (response.data.response) {
                    setRules(response.data.data)
                }
                else {
                    console.error('Failed to fetch rules: ', response.data.error)
                }
            } catch (error) {
                console.error('Error fetching rules: ', error)
            }
        }
    }

    useEffect(() => {
        fetchDesignationList()
        fetchRules()
    }, [])

    function filterFunc(e) {
        let temp = designationList.filter((v, i) => {
            return v.designation.toLowerCase().indexOf(e.toLowerCase()) !== -1
        })
        setFilterArr(temp)
    }

    return (
        <>
            {
                rules.length > 0
                    ?
                    (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1 || rules[0].Allow_View == 1)
                        ?
                        <Box sx={{ display: 'flex', flexDirection: "column", height: "auto", minWidth: 1, padding: 1 }}>
                            <Box sx={{ display: { xs: 'block', sm: 'flex' }, justifyContent: "space-between", }}>
                                <Box sx={{ display: 'flex' }}>
                                    <Card sx={{
                                        margin: 0.5, mr: 1, padding: 1, paddingTop: 1, display: "flex", justifyContent: "center", alignItems: "center",
                                    }}>{<ThreePIcon fontSize="large" />}</Card>
                                    <Typography sx={{ mt: "auto", mb: "auto", fontSize: { xs: '1.5rem', sm: "2rem" } }}>Designations</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1, mt: 1, marginBottom: { xs: '10px', sm: "0px" } }}>
                                    <TextField
                                        label={`Search Designation`}
                                        type="search"
                                        size='small'
                                        sx={{ width: { sx: "100%" }, }}
                                        style={{ marginRight: 5 }}
                                        onChange={(e) => filterFunc(e.target.value)}
                                    />
                                    <Button
                                        size="small" variant='outlined' sx={{ height: 40, width: 160 }}
                                        onClick={() => openModal1()}
                                        disabled={
                                            rules.length > 0
                                                ?
                                                (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                    ?
                                                    false
                                                    :
                                                    rules[0].Allow_Add == 1
                                                        ?
                                                        false
                                                        :
                                                        true
                                                :
                                                true
                                        }
                                    >Add Designation</Button>
                                </Box>
                            </Box>
                            <table className="table table-bordered" id="table-main">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>S. No</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Designation</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Short Form</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Scale</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Action</th>
                                    </tr>
                                </thead>
                                {
                                    filterArr.length
                                        ?
                                        filterArr.map((v2, i2) => {
                                            return <>
                                                <tbody>
                                                    <tr key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                                        <th style={{ padding: 2, textAlign: "center", verticalAlign: "middle" }}>{(i2 + 1).toString().length < 2 ? "0" + (i2 + 1) : (i2 + 1)}</th>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.designation}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.designation_short}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.scale}</td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            <Button
                                                                onClick={() => setupedit(v2)}
                                                                size="small" variant='outlined' sx={{ height: 25, marginRight: 1, width: 60 }}
                                                                disabled={
                                                                    rules.length > 0
                                                                        ?
                                                                        (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                                            ?
                                                                            false
                                                                            :
                                                                            rules[0].Allow_Edit == 1
                                                                                ?
                                                                                false
                                                                                :
                                                                                true
                                                                        :
                                                                        true
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                            {/* <Button
                                                        color="error"
                                                        onClick={() => deletefunc(v2)}
                                                        size="small" variant='outlined' sx={{ height: 25, marginRight: 1, width: 60 }}
                                                    >
                                                        Delete
                                                    </Button> */}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </>
                                        })
                                        :
                                        designationList.map((v2, i2) => {
                                            return <>
                                                <tbody>
                                                    <tr key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                                        <th style={{ padding: 2, textAlign: "center", verticalAlign: "middle" }}>{(i2 + 1).toString().length < 2 ? "0" + (i2 + 1) : (i2 + 1)}</th>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.designation}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.designation_short}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.scale}</td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            <Button
                                                                onClick={() => setupedit(v2)}
                                                                size="small" variant='outlined' sx={{ height: 25, marginRight: 1, width: 60 }}
                                                                disabled={
                                                                    rules.length > 0
                                                                        ?
                                                                        (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                                            ?
                                                                            false
                                                                            :
                                                                            rules[0].Allow_Edit == 1
                                                                                ?
                                                                                false
                                                                                :
                                                                                true
                                                                        :
                                                                        true
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                            {/* <Button
                                                        color="error"
                                                        onClick={() => deletefunc(v2)}
                                                        size="small" variant='outlined' sx={{ height: 25, marginRight: 1, width: 60 }}
                                                    >
                                                        Delete
                                                    </Button> */}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </>
                                        })
                                }
                            </table>

                            <table
                                className="table"
                                id="table-main-small">
                                {
                                    filterArr.length
                                        ?
                                        filterArr.map((v1, i1) => {
                                            return <>
                                                <tbody key={i1 + 1} style={{ backgroundColor: ((i1 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Designation: {v1.designation}</td>
                                                        <td scope="col" id="mobile-style" rowSpan={2} style={{ textAlign: "right" }}>
                                                            <Button
                                                                onClick={() => setupedit(v1)}
                                                                size="small" variant='outlined' sx={{ height: 25, width: 60, marginBottom: 1 }}
                                                                disabled={
                                                                    rules.length > 0
                                                                        ?
                                                                        (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                                            ?
                                                                            false
                                                                            :
                                                                            rules[0].Allow_Edit == 1
                                                                                ?
                                                                                false
                                                                                :
                                                                                true
                                                                        :
                                                                        true
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Short Form: {v1.designation_short}</td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Scale: {v1.scale}</td>
                                                        <td scope="col" id="mobile-style" ></td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" ></td>
                                                        <td scope="col" id="mobile-style" style={{ textAlign: "right" }}>
                                                            {/* <Button
                                                        color="error"
                                                        onClick={() => deletefunc(v1)}
                                                        size="small" variant='outlined' sx={{ height: 25, marginBottom: 1, width: 60 }}
                                                    >
                                                        Delete
                                                    </Button> */}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <hr style={{ margin: 5, padding: 0 }} />
                                                <hr style={{ margin: 5, padding: 0 }} />
                                            </>
                                        })
                                        :
                                        designationList.map((v2, i2) => {
                                            return <>
                                                <tbody key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                                    <tr key={i2 + 1}>
                                                        <td scope="col" id="mobile-style" >Designation: {v2.designation}</td>
                                                        <td scope="col" id="mobile-style" rowSpan={2} style={{ textAlign: "right" }}>
                                                            <Button
                                                                onClick={() => setupedit(v2)}
                                                                size="small" variant='outlined' sx={{ height: 25, width: 60, marginBottom: 1 }}
                                                                disabled={
                                                                    rules.length > 0
                                                                        ?
                                                                        (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                                            ?
                                                                            false
                                                                            :
                                                                            rules[0].Allow_Edit == 1
                                                                                ?
                                                                                false
                                                                                :
                                                                                true
                                                                        :
                                                                        true
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                    <tr key={i2 + 1}>
                                                        <td scope="col" id="mobile-style" >Short Form: {v2.designation_short}</td>
                                                    </tr>
                                                    <tr key={i2 + 1}>
                                                        <td scope="col" id="mobile-style" >Scale: {v2.scale}</td>
                                                        <td scope="col" id="mobile-style" ></td>
                                                    </tr>
                                                    <tr key={i2 + 1}>
                                                        <td scope="col" id="mobile-style" ></td>
                                                        <td scope="col" id="mobile-style" style={{ textAlign: "right" }}>
                                                            {/* <Button
                                                        color="error"
                                                        onClick={() => deletefunc(v2)}
                                                        size="small" variant='outlined' sx={{ height: 25, marginBottom: 1, width: 60 }}
                                                    >
                                                        Delete
                                                    </Button> */}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <hr style={{ margin: 5, padding: 0 }} />
                                            </>
                                        })
                                }
                            </table>
                            <Box>
                                <Dialog
                                    open={modalIsOpen1}
                                    onClose={closeModal1}
                                    PaperProps={{
                                        component: 'form',
                                        sx: {
                                            width: { xs: '100%', sm: '70%' },
                                        },
                                    }}
                                >
                                    <DialogTitle sx={{ fontSize: { xs: "2rem", md: "2.5rem" } }} >{`Add Designation`}</DialogTitle>
                                    <DialogContent sx={{
                                        '& .MuiInputBase-input': { fontSize: '1.5rem' },
                                        '& .MuiInputLabel-root': { fontSize: '1.25rem' },
                                    }}>
                                        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                                            <TextField required fullWidth variant="standard"
                                                id="designation"
                                                type="text"
                                                name="designation"
                                                label="Designation:"
                                                value={designationData.designation}
                                                onChange={handleInputChange}
                                            />
                                            <TextField required fullWidth variant="standard"
                                                id="designation_short"
                                                type="text"
                                                name="designation_short"
                                                label="Short Form:"
                                                value={designationData.designation_short}
                                                onChange={handleInputChange}
                                            />
                                            <TextField required fullWidth variant="standard"
                                                id="scale"
                                                name="scale"
                                                label="Scale:"
                                                value={designationData.scale}
                                                onChange={handleInputChange}
                                                select
                                            >
                                                <MenuItem value="1">1</MenuItem>
                                                <MenuItem value="2">2</MenuItem>
                                                <MenuItem value="3">3</MenuItem>
                                                <MenuItem value="4">4</MenuItem>
                                                <MenuItem value="5">5</MenuItem>
                                                <MenuItem value="6">6</MenuItem>
                                                <MenuItem value="7">7</MenuItem>
                                                <MenuItem value="8">8</MenuItem>
                                            </TextField>
                                        </Box>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={closeModal1}
                                            disabled={buttonMessage !== '' ? true : false}
                                        >
                                            Cancel
                                        </Button>
                                        <Button type="submit"
                                            onClick={addDesignation}
                                            disabled={buttonMessage !== '' ? true : false}
                                        >
                                            {buttonMessage !== '' ? buttonMessage : "Add"}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Box>

                            <Box>
                                <Dialog
                                    open={modalIsOpen2}
                                    onClose={closeModal2}
                                    PaperProps={{
                                        component: 'form',
                                        sx: {
                                            width: { xs: '100%', sm: '70%' },
                                        },
                                    }}
                                >
                                    <DialogTitle sx={{ fontSize: { xs: "2rem", md: "2.5rem" } }} >{`Edit Designation`}</DialogTitle>
                                    <DialogContent sx={{
                                        '& .MuiInputBase-input': { fontSize: '1.5rem' },
                                        '& .MuiInputLabel-root': { fontSize: '1.25rem' },
                                    }}>
                                        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                                            <TextField required fullWidth variant="standard"
                                                id="designation"
                                                type="text"
                                                name="designation"
                                                label="Designation:"
                                                value={editDesignation.designation}
                                                onChange={handledata}
                                            />
                                            <TextField required fullWidth variant="standard"
                                                id="designation_short"
                                                type="text"
                                                name="designation_short"
                                                label="Short Form:"
                                                value={editDesignation.designation_short}
                                                onChange={handledata}
                                            />
                                            <TextField required fullWidth variant="standard"
                                                id="scale"
                                                name="scale"
                                                label="Scale:"
                                                value={editDesignation.scale}
                                                onChange={handledata}
                                                select
                                            >
                                                <MenuItem value="1">1</MenuItem>
                                                <MenuItem value="2">2</MenuItem>
                                                <MenuItem value="3">3</MenuItem>
                                                <MenuItem value="4">4</MenuItem>
                                                <MenuItem value="5">5</MenuItem>
                                                <MenuItem value="6">6</MenuItem>
                                                <MenuItem value="7">7</MenuItem>
                                                <MenuItem value="8">8</MenuItem>
                                            </TextField>
                                        </Box>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={closeModal2}
                                            disabled={buttonMessage !== '' ? true : false}
                                        >
                                            Cancel
                                        </Button>
                                        <Button type="submit"
                                            onClick={editDesignationSubmit}
                                            disabled={buttonMessage !== '' ? true : false}
                                        >
                                            {buttonMessage !== '' ? buttonMessage : "Submit"}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Box>
                            <Toaster />
                        </Box>
                        :
                        <div style={{ padding: 10 }}>View disabled</div>
                    :
                    ""
            }
        </>
    )
}

export default Designation