import React, { useState, useEffect } from 'react'
import axios from "axios"
import Modal from 'react-modal'
import Cookies from 'js-cookie'
import { Box, Button, TextField, Card, Typography, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import toast, { Toaster } from 'react-hot-toast'
import { capitalizeValues } from '../utils/formatters'

const Attendance = () => {

    const [modalIsOpen1, setIsOpen1] = useState(false)
    const [filterArr, setFilterArr] = useState([])
    const [editAttendance, setEditAttendance] = useState({})
    const [modalIsOpen2, setIsOpen2] = useState(false)
    const [rules, setRules] = useState([])

    const openModal1 = () => {
        setIsOpen1(true)
    }

    const setupedit = (v) => {
        setEditAttendance(v)
        setIsOpen2(true)
    }

    function handledata(e) {
        setEditAttendance({ ...editAttendance, [e.target.name]: e.target.value })
    }

    const closeModal2 = () => {
        setIsOpen2(false)
    }

    const editAttendanceSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post('/api/editAttendanceSubmit', {
                body: editAttendance
            })
            if (response.data.success) {
                toast.success(response.data.message, {
                    duration: 5000,
                    position: 'bottom-right',
                    style: {
                        borderBottom: "3px solid #61D345",
                        minWidth: '300px',
                        minHeight: "50px"
                    }
                })
                fetchAttendanceList()
                closeModal2()
            } else {
                console.error("Failed to edit attendance: ", response.data.error)
            }
        } catch (error) {
            console.error('Error editing attendance: ', error)
        }
    }

    const closeModal1 = () => {
        setIsOpen1(false)
        setAttendanceData({
            ...attendanceData,
            attendance_name: '',
            symbol: '',
            type: '',
        })
    }

    const [attendanceData, setAttendanceData] = useState({
        company_id: Cookies.get('userInfo'),
        attendance_name: '',
        symbol: '',
        type: '',
    })

    const [attendanceList, setAttendanceList] = useState([])

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setAttendanceData({
            ...attendanceData,
            [name]: value,
        })
    }

    const addAttendance = async (e) => {
        e.preventDefault()
        if (attendanceData.attendance_name === "" || attendanceData.symbol === "" || attendanceData.type === "") {
            toast.error("All fields are required", {
                duration: 5000,
                position: 'bottom-right',
                style: {
                    minWidth: '300px',
                    minHeight: "50px"
                }
            })
        } else {
            try {
                const response = await axios.post('/api/addAttendance', {
                    body: attendanceData
                })
                if (response.data.success) {
                    toast.success(response.data.message, {
                        duration: 5000,
                        position: 'bottom-right',
                        style: {
                            borderBottom: "3px solid #61D345",
                            minWidth: '300px',
                            minHeight: "50px"
                        }
                    })
                    closeModal1()
                    fetchAttendanceList()
                } else {
                    console.error('Failed to add attendance: ', response.data.error)
                }
            } catch (error) {
                console.error('Error adding attendance: ', error)
            }
        }
    }

    const fetchAttendanceList = async () => {
        try {
            const response = await axios.post('/api/fetchAttendanceList', {
                id: Cookies.get('userInfo')
            })
            if (response.data.response) {
                setAttendanceList(capitalizeValues(response.data.data))
            }
            else {
                console.error('Failed to fetch attendance list: ', response.data.error)
            }
        } catch (error) {
            console.error('Error fetching attendance list: ', error)
        }
    }

    const fetchRules = async () => {
        let id = Cookies.get('eid')
        let userInfo = Cookies.get('userInfo')
        if (id) {
            try {
                const response = await axios.post('/api/fetchRules', {
                    id: id,
                    module: "frm_DefaultAttendance_List"
                })
                if (response.data.response) {
                    setRules(response.data.data)
                }
                else {
                    console.error('Failed to fetch rules: ', response.data.error)
                }
            } catch (error) {
                console.error('Error fetching rules: ', error)
            }
        }
        else {
            try {
                const response = await axios.post('/api/fetchRules', {
                    userInfo: userInfo,
                })
                if (response.data.response) {
                    setRules(response.data.data)
                }
                else {
                    console.error('Failed to fetch rules: ', response.data.error)
                }
            } catch (error) {
                console.error('Error fetching rules: ', error)
            }
        }
    }

    useEffect(() => {
        fetchAttendanceList()
        fetchRules()
    }, [])

    function filterFunc(e) {
        let temp = attendanceList.filter((v, i) => {
            return v.attendance_name.toLowerCase().indexOf(e.toLowerCase()) !== -1
        })
        setFilterArr(temp)
    }

    async function deletefunc(v) {
        try {
            const response = await axios.post('/api/deleteattendance', {
                body: v
            })
            if (response.data.response) {
                fetchAttendanceList()
                toast.success("Successfully deleted", {
                    duration: 5000,
                    position: 'bottom-right',
                    style: {
                        borderBottom: "3px solid #61D345",
                        minWidth: '300px',
                        minHeight: "50px"
                    }
                })
            }
            else {
                console.error('Failed to delete attendance: ', response.data.error)
            }
        } catch (error) {
            console.error('Error deleting attendance: ', error)
        }
    }

    return (
        <>
            {
                rules.length > 0
                    ?
                    (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1 || rules[0].Allow_View == 1)
                        ?
                        <Box sx={{ display: 'flex', flexDirection: "column", height: "auto", minWidth: 1, padding: 1 }}>
                            <Box sx={{ display: { xs: 'block', sm: 'flex' }, justifyContent: "space-between", }}>
                                <Box sx={{ display: 'flex' }}>
                                    <Card sx={{
                                        margin: 0.5, mr: 1, padding: 1, paddingTop: 1, display: "flex", justifyContent: "center", alignItems: "center",
                                    }}>{<AccessTimeIcon fontSize="large" />}</Card>
                                    <Typography sx={{ mt: "auto", mb: "auto", fontSize: { xs: '1.5rem', sm: "2rem" } }}>Attendances</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1, mt: 1, marginBottom: { xs: '10px', sm: "0px" } }}>
                                    <TextField
                                        style={{ marginRight: 5 }}
                                        onChange={(e) => filterFunc(e.target.value)}
                                        label={`Search Attendance`}
                                        type="search"
                                        size='small'
                                        sx={{ width: { sx: "100%" }, }}
                                    />
                                    {/* <Button
                                onClick={() => openModal1()}
                                size="small" variant='outlined' sx={{ height: 40 }}
                            >
                                Add New Attendance
                            </Button> */}
                                </Box>
                            </Box>
                            <table className="table table-bordered" id="table-main">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>S. No</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Attendance</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Symbol</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Type</th>
                                        {/* <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Action</th> */}
                                    </tr>
                                </thead>
                                {
                                    filterArr.length
                                        ?
                                        filterArr.map((v2, i2) => {
                                            return <>
                                                <tbody>
                                                    <tr key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                                        <th style={{ padding: 2, textAlign: "center", verticalAlign: "middle" }}>{(i2 + 1).toString().length < 2 ? "0" + (i2 + 1) : (i2 + 1)}</th>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.attendance_name}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.symbol}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.type}</td>
                                                        {/* <td style={{ verticalAlign: "middle" }}>
                                                    <Button
                                                        onClick={() => setupedit(v2)}
                                                        size="small" variant='outlined' sx={{ height: 25, marginRight: 1, width: 60 }}
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        color="error"
                                                        onClick={() => deletefunc(v2)}
                                                        size="small" variant='outlined' sx={{ height: 25, marginRight: 1, width: 60 }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </td> */}
                                                    </tr>
                                                </tbody>
                                            </>
                                        })
                                        :
                                        attendanceList.map((v2, i2) => {
                                            return <>
                                                <tbody>
                                                    <tr key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                                        <th style={{ padding: 2, textAlign: "center", verticalAlign: "middle" }}>{(i2 + 1).toString().length < 2 ? "0" + (i2 + 1) : (i2 + 1)}</th>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.attendance_name}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.symbol}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.type}</td>
                                                        {/* <td style={{ verticalAlign: "middle" }}>
                                                    <Button
                                                        onClick={() => setupedit(v2)}
                                                        size="small" variant='outlined' sx={{ height: 25, marginRight: 1, width: 60 }}
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        color="error"
                                                        onClick={() => deletefunc(v2)}
                                                        size="small" variant='outlined' sx={{ height: 25, marginRight: 1, width: 60 }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </td> */}
                                                    </tr>
                                                </tbody>
                                            </>
                                        })
                                }
                            </table>
                            <table
                                className="table"
                                id="table-main-small">
                                {
                                    filterArr.length
                                        ?
                                        filterArr.map((v1, i1) => {
                                            return <>
                                                <tbody key={i1 + 1} style={{ backgroundColor: ((i1 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Holiday Name: {v1.attendance_name}</td>
                                                        <td scope="col" rowSpan={2} id="mobile-style" style={{ textAlign: "right" }}>
                                                            {/* <Button
                                                        onClick={() => setupedit(v1)}
                                                        size="small" variant='outlined' sx={{ height: 20, width: 70, marginBottom: 1 }}>
                                                        Edit
                                                    </Button> */}
                                                        </td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Symbol: {v1.symbol}</td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Type: {v1.type}</td>
                                                        <td scope="col" id="mobile-style" style={{ textAlign: "right" }}>
                                                            {/* <Button
                                                        color="error"
                                                        onClick={() => deletefunc(v1)}
                                                        size="small" variant='outlined' sx={{ height: 20, width: 70, marginBottom: 1 }}
                                                    >
                                                        Delete
                                                    </Button> */}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <hr style={{ margin: 5, padding: 0 }} />
                                            </>
                                        })
                                        :
                                        attendanceList.map((v1, i1) => {
                                            return <>
                                                <tbody key={i1 + 1} style={{ backgroundColor: ((i1 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Holiday Name: {v1.attendance_name}</td>
                                                        {/* <td scope="col" rowSpan={2} id="mobile-style" style={{ textAlign: "right" }}>
                                                    <Button
                                                        onClick={() => setupedit(v1)}
                                                        size="small" variant='outlined' sx={{ height: 20, width: 70, marginBottom: 1 }}>
                                                        Edit
                                                    </Button>
                                                </td> */}
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Symbol: {v1.symbol}</td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Type: {v1.type}</td>
                                                        <td scope="col" id="mobile-style" style={{ textAlign: "right" }}>
                                                            {/* <Button
                                                        color="error"
                                                        onClick={() => deletefunc(v1)}
                                                        size="small" variant='outlined' sx={{ height: 20, width: 70, marginBottom: 1 }}
                                                    >
                                                        Delete
                                                    </Button> */}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <hr style={{ margin: 5, padding: 0 }} />
                                            </>
                                        })
                                }
                            </table>
                            <Box>
                                <Dialog
                                    open={modalIsOpen1}
                                    onClose={closeModal1}
                                    PaperProps={{
                                        component: 'form',
                                        sx: {
                                            width: { xs: '100%', sm: '70%' },
                                        },
                                    }}
                                >
                                    <DialogTitle fontSize='2.5rem'  >{`Add Attendance`}</DialogTitle>
                                    <DialogContent sx={{
                                        '& .MuiInputBase-input': { fontSize: '1.5rem' },
                                        '& .MuiInputLabel-root': { fontSize: '1.25rem' },
                                    }}>
                                        <Box display="flex" flexDirection="column" gap={2}>
                                            <TextField autoFocus fullWidth variant="standard"
                                                id="attendance_name"
                                                type="text"
                                                name="attendance_name"
                                                label="Attendance Name:"
                                                value={attendanceData.attendance_name}
                                                onChange={handleInputChange}

                                            />
                                            <Box display="flex" sx={{ flexDirection: { xs: "column", md: "row" } }} gap={2}>
                                                <TextField autoFocus fullWidth variant="standard"
                                                    id="symbol"
                                                    type="text"
                                                    name="symbol"
                                                    label="Attendance Symbol:"
                                                    value={attendanceData.symbol}
                                                    onChange={handleInputChange}
                                                />
                                                <TextField fullWidth variant="standard"
                                                    id="type"
                                                    name="type"
                                                    label="Type:"
                                                    value={attendanceData.type}
                                                    onChange={handleInputChange}
                                                    select
                                                >
                                                    <MenuItem value="Paid">Paid</MenuItem>
                                                    <MenuItem value="Paid with deduction">Paid with deduction</MenuItem>
                                                    <MenuItem value="Unpaid">Unpaid</MenuItem>
                                                </TextField>
                                            </Box>
                                        </Box>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={closeModal1}>Cancel & Close</Button>
                                        <Button type="submit" onClick={addAttendance} >Submit</Button>
                                    </DialogActions>
                                </Dialog>
                            </Box>
                            {/* Edit */}
                            <Box>
                                <Dialog
                                    open={modalIsOpen2}
                                    onClose={closeModal2}
                                    PaperProps={{
                                        component: 'form',
                                        sx: {
                                            width: { xs: '100%', sm: '70%' },
                                        },
                                    }}
                                >
                                    <DialogTitle fontSize='2.5rem'  >{`Edit Attendance`}</DialogTitle>
                                    <DialogContent sx={{
                                        '& .MuiInputBase-input': { fontSize: '1.5rem' },
                                        '& .MuiInputLabel-root': { fontSize: '1.25rem' },
                                    }}>
                                        <Box display="flex" flexDirection="column" gap={2}>
                                            <TextField autoFocus fullWidth variant="standard"
                                                id="attendance_name"
                                                type="text"
                                                name="attendance_name"
                                                label="Attendance Name:"
                                                value={editAttendance.attendance_name}
                                                onChange={handledata}
                                            />
                                            <Box display="flex" sx={{ flexDirection: { xs: "column", md: "row" } }} gap={2}>
                                                <TextField autoFocus fullWidth variant="standard"
                                                    id="symbol"
                                                    type="text"
                                                    name="symbol"
                                                    label="Attendance Symbol:"
                                                    value={editAttendance.symbol}
                                                    onChange={handledata}
                                                />
                                                <TextField fullWidth variant="standard"
                                                    id="type"
                                                    name="type"
                                                    label="Type:"
                                                    value={editAttendance.type}
                                                    onChange={handledata}
                                                    select
                                                >
                                                    <MenuItem value="Paid">Paid</MenuItem>
                                                    <MenuItem value="Paid with deduction">Paid with deduction</MenuItem>
                                                    <MenuItem value="Unpaid">Unpaid</MenuItem>
                                                </TextField>
                                            </Box>
                                        </Box>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={closeModal2}>Cancel & Close</Button>
                                        <Button type="submit" onClick={editAttendanceSubmit} >Submit</Button>
                                    </DialogActions>
                                </Dialog>
                            </Box>
                            <Toaster />
                        </Box >
                        :
                        <div style={{ padding: 10 }}>View disabled</div>
                    :
                    ""
            }
        </>
    )
}

export default Attendance