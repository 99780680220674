import React, { useState, useEffect } from 'react'
import axios from "axios"
import Modal from 'react-modal'
import Cookies from 'js-cookie'
import { Box, Button, TextField, Card, Typography, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import BeachAccessIcon from '@mui/icons-material/BeachAccess'
import "./css/pages.css"
import toast, { Toaster } from 'react-hot-toast'
import { capitalizeValues } from '../utils/formatters'

const Holidays = () => {

    const [modalIsOpen1, setIsOpen1] = useState(false)
    const [filterArr, setFilterArr] = useState([])
    const [editHoliday, setEditHoliday] = useState({})
    const [modalIsOpen2, setIsOpen2] = useState(false)
    const [rules, setRules] = useState([])

    const [buttonMessage, setButtonMessage] = useState('')

    const setupedit = (v) => {
        setEditHoliday(v)
        setIsOpen2(true)
    }

    function handledata(e) {
        setEditHoliday({ ...editHoliday, [e.target.name]: e.target.value })
    }

    const closeModal2 = () => {
        setIsOpen2(false)
    }

    const editholidaysubmit = async (e) => {
        e.preventDefault()
        try {
            setButtonMessage("Submitting")
            const response = await axios.post('/api/editholidaysubmit', {
                body: editHoliday
            })
            if (response.data.success) {
                setButtonMessage('')
                toast.success(response.data.message, {
                    duration: 5000,
                    position: 'bottom-right',
                    style: {
                        borderBottom: "3px solid #61D345",
                        minWidth: '300px',
                        minHeight: "50px"
                    }
                })
                fetchHolidaysList('')
                closeModal2()
            } else {
                setButtonMessage('')
                console.error('Failed to update details: ', response.data.error)
            }
        } catch (error) {
            setButtonMessage('')
            console.error('Error editing holiday: ', error)
        }
    }

    const openModal1 = () => {
        setIsOpen1(true)
    }

    const closeModal1 = () => {
        setIsOpen1(false)
        setHolidaysData({
            ...holidaysData,
            holidays_name: '',
            hddate: ''
        })
    }

    const [holidaysData, setHolidaysData] = useState({
        company_id: Cookies.get('userInfo'),
        holidays_name: '',
        hddate: '',
    })

    const [holidaysList, setHolidaysList] = useState([])

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setHolidaysData({
            ...holidaysData,
            [name]: value,
        })
    }

    function changeTimeRegion(dateString) {
        if (dateString !== undefined) {
            const utcDate = new Date(dateString)

            const formatter = new Intl.DateTimeFormat('en-PK', {
                timeZone: 'Asia/Karachi',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                // hour: '2-digit',
                // minute: '2-digit',
                // second: '2-digit'
            })

            let formValue = formatter.format(utcDate)

            const year = formValue.slice(6, 10)
            const month = formValue.slice(3, 5)
            const day = formValue.slice(0, 2)

            // Format the date as "YYYY-MM-DD"
            const formattedDate = `${year}-${month}-${day}`

            return formattedDate
        }
        // return undefined
    }

    const addHolidays = async (e) => {
        e.preventDefault()
        if (holidaysData.holidays_name === "" || holidaysData.hddate === "") {
            toast.error("All fields are required", {
                duration: 5000,
                position: 'bottom-right',
                style: {
                    minWidth: '300px',
                    minHeight: "50px"
                }
            })
        } else {
            try {
                setButtonMessage('Adding')
                const response = await axios.post('/api/addHolidays', {
                    body: holidaysData
                })
                if (response.data.success) {
                    setButtonMessage('')
                    toast.success(response.data.message, {
                        duration: 5000,
                        position: 'bottom-right',
                        style: {
                            borderBottom: "3px solid #61D345",
                            minWidth: '300px',
                            minHeight: "50px"
                        }
                    })
                    closeModal1()
                    fetchHolidaysList()
                } else {
                    setButtonMessage('')
                    console.error('Failed to add holiday: ', response.data.error)
                }
            } catch (error) {
                setButtonMessage('')
                console.error('Error adding holiday: ', error)
            }
        }
    }

    const fetchHolidaysList = async () => {
        try {
            const response = await axios.post('/api/fetchHolidaysList', {
                id: Cookies.get('userInfo')
            })
            if (response.data.response) {
                setHolidaysList(capitalizeValues(response.data.data))
            }
            else {
                console.error('Failed to fetch holidays list: ', response.data.error)
            }
        } catch (error) {
            console.error('Error fetching holidays list: ', error)
        }
    }

    const fetchRules = async () => {
        let id = Cookies.get('eid')
        let userInfo = Cookies.get('userInfo')
        if (id) {
            try {
                const response = await axios.post('/api/fetchRules', {
                    id: id,
                    module: "frm_Holiday_List"
                })
                if (response.data.response) {
                    setRules(response.data.data)
                }
                else {
                    console.error('Failed to fetch rules: ', response.data.error)
                }
            } catch (error) {
                console.error('Error fetching rules: ', error)
            }
        }
        else {
            try {
                const response = await axios.post('/api/fetchRules', {
                    userInfo: userInfo,
                })
                if (response.data.response) {
                    setRules(response.data.data)
                }
                else {
                    console.error('Failed to fetch rules: ', response.data.error)
                }
            } catch (error) {
                console.error('Error fetching rules: ', error)
            }
        }
    }

    useEffect(() => {
        fetchHolidaysList()
        fetchRules()
    }, [])

    function filterFunc(e) {
        let temp = holidaysList.filter((v, i) => {
            return v.holidays_name.toLowerCase().indexOf(e.toLowerCase()) !== -1
        })
        setFilterArr(temp)
    }

    async function deletefunc(v) {
        try {
            setButtonMessage('Deleting')
            const response = await axios.post('/api/deleteholiday', {
                body: v
            })
            if (response.data.response) {
                setButtonMessage('')
                fetchHolidaysList()
                toast.success("Successfully deleted", {
                    duration: 5000,
                    position: 'bottom-right',
                    style: {
                        borderBottom: "3px solid #61D345",
                        minWidth: '300px',
                        minHeight: "50px"
                    }
                })
            }
            else {
                setButtonMessage('')
                console.error('Failed to delete holiday: ', response.data.error)
            }
        } catch (error) {
            setButtonMessage('')
            console.error('Error deleting holiday: ', error)
        }
    }

    return (
        <>
            {
                rules.length > 0
                    ?
                    (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1 || rules[0].Allow_View == 1)
                        ?
                        < Box
                            sx={{ display: 'flex', flexDirection: "column", height: "auto", minWidth: 1, padding: 1 }
                            }>
                            <Box sx={{ display: { xs: 'block', sm: 'flex' }, justifyContent: "space-between", }}>
                                <Box sx={{ display: 'flex' }}>
                                    <Card sx={{
                                        margin: 0.5, mr: 1, padding: 1, paddingTop: 1, display: "flex", justifyContent: "center", alignItems: "center",
                                    }}>{<BeachAccessIcon fontSize="large" />}</Card>
                                    <Typography sx={{ mt: "auto", mb: "auto", fontSize: { xs: '1.5rem', sm: "2rem" } }}>Holidays</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1, mt: 1, marginBottom: { xs: '10px', sm: "0px" } }}>
                                    <TextField
                                        style={{ marginRight: 5 }}
                                        onChange={(e) => filterFunc(e.target.value)}

                                        label={`Search Holiday`}
                                        type="search"
                                        size='small'
                                        sx={{ width: { sx: "100%" }, }}
                                    />
                                    <Button
                                        size="small" variant='outlined' sx={{ height: 40, width: 120 }}
                                        onClick={() => openModal1()}
                                        disabled={
                                            rules.length > 0
                                                ?
                                                (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                    ?
                                                    false
                                                    :
                                                    rules[0].Allow_Add == 1
                                                        ?
                                                        false
                                                        :
                                                        true
                                                :
                                                true
                                        }
                                    >
                                        Add Holiday
                                    </Button>
                                </Box>
                            </Box>
                            <table className="table table-bordered" id="table-main">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>S. No</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Holiday Name</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Date</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Action</th>
                                    </tr>
                                </thead>
                                {
                                    filterArr.length
                                        ?
                                        filterArr.map((v2, i2) => {
                                            return <>
                                                <tbody>
                                                    <tr key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                                        <th style={{ padding: 2, textAlign: "center", verticalAlign: "middle" }}>{(i2 + 1).toString().length < 2 ? "0" + (i2 + 1) : (i2 + 1)}</th>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.holidays_name}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.hddate == '' ? '' : changeTimeRegion(v2.hddate)}</td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            <Button
                                                                onClick={() => setupedit(v2)}
                                                                size="small" variant='outlined' sx={{ height: 25, marginRight: 1, width: 60 }}
                                                                disabled={
                                                                    rules.length > 0
                                                                        ?
                                                                        (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                                            ?
                                                                            false
                                                                            :
                                                                            rules[0].Allow_Edit == 1
                                                                                ?
                                                                                false
                                                                                :
                                                                                true
                                                                        :
                                                                        true
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                            <Button
                                                                color="error"
                                                                onClick={() => deletefunc(v2)}
                                                                size="small" variant='outlined' sx={{ height: 25, marginRight: 1, width: 60 }}
                                                                disabled={
                                                                    buttonMessage !== '' ? true :
                                                                        rules.length > 0
                                                                            ?
                                                                            (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                                                ?
                                                                                false
                                                                                :
                                                                                rules[0].Allow_Delete == 1
                                                                                    ?
                                                                                    false
                                                                                    :
                                                                                    true
                                                                            :
                                                                            true
                                                                }
                                                            >
                                                                Delete
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </>
                                        })
                                        :
                                        holidaysList.map((v2, i2) => {
                                            return <>
                                                <tbody>
                                                    <tr key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                                        <th style={{ padding: 2, textAlign: "center", verticalAlign: "middle" }}>{(i2 + 1).toString().length < 2 ? "0" + (i2 + 1) : (i2 + 1)}</th>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.holidays_name}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.hddate == '' ? '' : changeTimeRegion(v2.hddate)}</td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            <Button
                                                                onClick={() => setupedit(v2)}
                                                                size="small" variant='outlined' sx={{ height: 25, marginRight: 1, width: 60 }}
                                                                disabled={
                                                                    rules.length > 0
                                                                        ?
                                                                        (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                                            ?
                                                                            false
                                                                            :
                                                                            rules[0].Allow_Edit == 1
                                                                                ?
                                                                                false
                                                                                :
                                                                                true
                                                                        :
                                                                        true
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                            <Button
                                                                color="error"
                                                                onClick={() => deletefunc(v2)}
                                                                size="small" variant='outlined' sx={{ height: 25, marginRight: 1, width: 60 }}
                                                                disabled={
                                                                    buttonMessage !== '' ? true :
                                                                        rules.length > 0
                                                                            ?
                                                                            (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                                                ?
                                                                                false
                                                                                :
                                                                                rules[0].Allow_Delete == 1
                                                                                    ?
                                                                                    false
                                                                                    :
                                                                                    true
                                                                            :
                                                                            true
                                                                }
                                                            >
                                                                Delete
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </>
                                        })
                                }
                            </table>
                            <table
                                className="table"
                                id="table-main-small">
                                {
                                    filterArr.length
                                        ?
                                        filterArr.map((v1, i1) => {
                                            return <>
                                                <tbody key={i1 + 1} style={{ backgroundColor: ((i1 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Holiday Name: {v1.holidays_name}</td>
                                                        <td scope="col" rowSpan={2} id="mobile-style" style={{ textAlign: "right" }}>
                                                            <Button
                                                                onClick={() => setupedit(v1)}
                                                                size="small" variant='outlined' sx={{ height: 20, width: 70, marginBottom: 1 }}
                                                                disabled={
                                                                    rules.length > 0
                                                                        ?
                                                                        (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                                            ?
                                                                            false
                                                                            :
                                                                            rules[0].Allow_Edit == 1
                                                                                ?
                                                                                false
                                                                                :
                                                                                true
                                                                        :
                                                                        true
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Date: {v1.hddate == '' ? '' : changeTimeRegion(v1.hddate)}</td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" ></td>
                                                        <td scope="col" id="mobile-style" style={{ textAlign: "right" }}>
                                                            <Button
                                                                color="error"
                                                                onClick={() => deletefunc(v1)}
                                                                size="small" variant='outlined' sx={{ height: 20, width: 70, marginBottom: 1 }}
                                                                disabled={
                                                                    buttonMessage !== '' ? true :
                                                                        rules.length > 0
                                                                            ?
                                                                            (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                                                ?
                                                                                false
                                                                                :
                                                                                rules[0].Allow_Delete == 1
                                                                                    ?
                                                                                    false
                                                                                    :
                                                                                    true
                                                                            :
                                                                            true
                                                                }
                                                            >
                                                                Delete
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <hr style={{ margin: 5, padding: 0 }} />
                                            </>
                                        })
                                        :
                                        holidaysList.map((v1, i1) => {
                                            return <>
                                                <tbody key={i1 + 1} style={{ backgroundColor: ((i1 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Holiday Name: {v1.holidays_name}</td>
                                                        <td scope="col" rowSpan={2} id="mobile-style" style={{ textAlign: "right" }}>
                                                            <Button
                                                                onClick={() => setupedit(v1)}
                                                                size="small" variant='outlined' sx={{ height: 20, width: 70, marginBottom: 1 }}
                                                                disabled={
                                                                    rules.length > 0
                                                                        ?
                                                                        (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                                            ?
                                                                            false
                                                                            :
                                                                            rules[0].Allow_Edit == 1
                                                                                ?
                                                                                false
                                                                                :
                                                                                true
                                                                        :
                                                                        true
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Date: {v1.hddate == '' ? '' : changeTimeRegion(v1.hddate)}</td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" ></td>
                                                        <td scope="col" id="mobile-style" style={{ textAlign: "right" }}>
                                                            <Button
                                                                color="error"
                                                                onClick={() => deletefunc(v1)}
                                                                size="small" variant='outlined' sx={{ height: 20, width: 70, marginBottom: 1 }}
                                                                disabled={
                                                                    buttonMessage !== '' ? true :
                                                                        rules.length > 0
                                                                            ?
                                                                            (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                                                ?
                                                                                false
                                                                                :
                                                                                rules[0].Allow_Delete == 1
                                                                                    ?
                                                                                    false
                                                                                    :
                                                                                    true
                                                                            :
                                                                            true
                                                                }
                                                            >
                                                                Delete
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <hr style={{ margin: 5, padding: 0 }} />
                                            </>
                                        })
                                }
                            </table>
                            <Dialog
                                open={modalIsOpen1}
                                onClose={closeModal1}
                                PaperProps={{
                                    component: 'form',
                                    sx: {
                                        width: { xs: '100%', sm: '70%' },
                                    },
                                }}
                            >
                                <DialogTitle sx={{ fontSize: { xs: "2rem", md: "2.5rem" } }} >{`Add Holiday`}</DialogTitle>
                                <DialogContent sx={{
                                    '& .MuiInputBase-input': { fontSize: '1.5rem' },
                                    '& .MuiInputLabel-root': { fontSize: '1.25rem' },
                                }}>
                                    <Box display="flex" flexDirection="column" gap={2}>
                                        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                                            <TextField autoFocus fullWidth variant="standard"
                                                id="holidays_name"
                                                type="text"
                                                name="holidays_name"
                                                label="Holiday Name:"
                                                value={holidaysData.holidays_name}
                                                onChange={handleInputChange}

                                            />
                                            <TextField required fullWidth variant="standard"
                                                id="hddate"
                                                name="hddate"
                                                label="Date"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    inputProps: {
                                                        max: '9999-12-31',
                                                        min: '1000-01-01',
                                                        maxLength: 4, // Restrict input length to 4 digits
                                                    },
                                                }}
                                                value={holidaysData.hddate}
                                                onChange={handleInputChange}
                                            // error={ErrorAlertOpendob}
                                            // onBlur={handleBlurdob}
                                            />
                                            {/* <TextField fullWidth variant="standard"
                                        id="type"
                                        name="type"
                                        label="Type:"
                                        value={holidaysData.type}
                                        onChange={handleInputChange}
                                        select
                                    >
                                        <MenuItem value="Public">Public</MenuItem>
                                        <MenuItem value="Optional">Optional</MenuItem>
                                    </TextField> */}
                                        </Box>

                                    </Box>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={closeModal1}
                                        disabled={buttonMessage !== '' ? true : false}
                                    >
                                        Cancel & Close
                                    </Button>
                                    <Button
                                        onClick={addHolidays}
                                        disabled={buttonMessage !== '' ? true : false}
                                    >
                                        {buttonMessage !== '' ? buttonMessage : "Add"}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog
                                open={modalIsOpen2}
                                onClose={closeModal2}
                                PaperProps={{
                                    component: 'form',
                                    sx: {
                                        width: { xs: '100%', sm: '70%' },
                                    },
                                }}
                            >
                                <DialogTitle sx={{ fontSize: { xs: "2rem", md: "2.5rem" } }} >{`Edit Holiday`}</DialogTitle>
                                <DialogContent sx={{
                                    '& .MuiInputBase-input': { fontSize: '1.5rem' },
                                    '& .MuiInputLabel-root': { fontSize: '1.25rem' },
                                }}>
                                    <Box display="flex" flexDirection="column" gap={2}>
                                        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
                                            <TextField autoFocus fullWidth variant="standard"
                                                id="holidays_name"
                                                type="text"
                                                name="holidays_name"
                                                label="Holiday Name:"
                                                value={editHoliday.holidays_name}
                                                onChange={handledata}
                                            />
                                            <TextField required fullWidth variant="standard"
                                                id="hddate"
                                                name="hddate"
                                                label="Date"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    inputProps: {
                                                        max: '9999-12-31',
                                                        min: '1000-01-01',
                                                        maxLength: 4, // Restrict input length to 4 digits
                                                    },
                                                }}
                                                value={editHoliday.hddate == '' ? '' : changeTimeRegion(editHoliday.hddate)}
                                                onChange={handledata}
                                            // error={ErrorAlertOpendob}
                                            // onBlur={handleBlurdob}
                                            />
                                            {/* <TextField fullWidth variant="standard"
                                        id="type"
                                        name="type"
                                        label="Type:"
                                        value={holidaysData.type}
                                        onChange={handleInputChange}
                                        select
                                    >
                                        <MenuItem value="Public">Public</MenuItem>
                                        <MenuItem value="Optional">Optional</MenuItem>
                                    </TextField> */}
                                        </Box>

                                    </Box>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={closeModal2}
                                        disabled={buttonMessage !== '' ? true : false}
                                    >
                                        Cancel & Close
                                    </Button>
                                    <Button onClick={editholidaysubmit}
                                        disabled={buttonMessage !== '' ? true : false}
                                    >
                                        {buttonMessage !== '' ? buttonMessage : "Submit"}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Toaster />
                        </Box >
                        :
                        <div style={{ padding: 10 }}>View disabled</div>
                    :
                    ""
            }
        </>
    )
}

export default Holidays