// Utility function to capitalize string values
export const capitalizeString = (value) => {
    if (typeof value !== 'string' || !value) return value;
    return value.toUpperCase();
};

// Function to recursively capitalize all string values in an object or array
export const capitalizeValues = (data) => {
    if (Array.isArray(data)) {
        return data.map(item => capitalizeValues(item));
    }
    
    if (data && typeof data === 'object') {
        const result = {};
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                result[key] = capitalizeValues(data[key]);
            }
        }
        return result;
    }
    
    return typeof data === 'string' ? capitalizeString(data) : data;
};
