import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { Box, Card, Typography, TextField, Button, IconButton } from '@mui/material'
import { MenuItem, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ClearIcon from '@mui/icons-material/Clear';
import "./css/pages.css"
import axios from "axios"
import MaskedTextField from 'react-masked-mui-textfield'
import Cookies from 'js-cookie'
import toast, { Toaster } from 'react-hot-toast'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { capitalizeString, capitalizeValues } from '../utils/formatters'

const DataTable = React.memo(function DataTable(props) {

  const { 
    title, 
    icon, 
    columns, 
    rows, 
    openModal1, 
    fetchEmployeeList, 
    rules,
    selectionProps,
    filterProps,
    onIncrementClick 
  } = props;

  const { selectedEmployees, onSelectEmployee, onSelectAll } = selectionProps;
  const { searchValue, filterRows, onSearch, onReset } = filterProps;

  const [newRows, setNewRows] = useState(rows)
  const [newRules, setRules] = useState(rules)
  const [editEmployee, setEditEmployee] = useState({})
  const [modalIsOpen2, setIsOpen2] = useState(false)
  const [departmentList, setDepartmentList] = useState([])
  const [designationList, setDesignationList] = useState([])
  const [branchList, setBranchList] = useState([])
  const [buttonMessage, setButtonMessage] = useState('')
  const [modalIsOpen3, setIsOpen3] = useState(false)
  const [fetchRules, setFetchRules] = useState([])

  // Safe string conversion helper
  const safeString = (value) => {
    if (value === null || value === undefined) return '';
    return String(value).toLowerCase();
  };


  // Update rows only when the props.rows actually changes
  useEffect(() => {
    if (rows?.length > 0) {
      setNewRows(rows);
    }
  }, [rows]);

  // Update rules only when props.rules changes
  useEffect(() => {
    if (rules?.length > 0) {
      setRules(rules);
    }
  }, [rules]);


  // Memoize the fetch functions
  const fetchDepartmentList = useCallback(async () => {
    try {
      const response = await axios.post('/api/fetchDepartmentList', {
        id: Cookies.get('userInfo')
      });
      if (response.data.response) {
        setDepartmentList(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching department list:', error);
    }
  }, []);

  const fetchDesignationList = useCallback(async () => {
    try {
      const response = await axios.post('/api/fetchDesignationList', {
        id: Cookies.get('userInfo')
      });
      if (response.data.response) {
        setDesignationList(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching designation list:', error);
    }
  }, []);

  const fetchBranchList = useCallback(async () => {
    try {
      const response = await axios.post('/api/fetchBranchList', {
        id: Cookies.get('userInfo')
      });
      if (response.data.response) {
        setBranchList(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching branch list:', error);
    }
  }, []);

  // Batch the fetch calls
  useEffect(() => {
    if (rows?.length > 0) {
      Promise.all([
        fetchDepartmentList(),
        fetchDesignationList(),
        fetchBranchList()
      ]);
    }
  }, [rows, fetchDepartmentList, fetchDesignationList, fetchBranchList]);

  function handledataDept(e) {
    departmentList.map((v, i) => {
      if (v.department_id === e.target.value) {
        setEditEmployee({ ...editEmployee, [e.target.name]: e.target.value, ait: v.inTime, lia: v.late, aot: v.outTime, eoa: v.early, halfdayin: v.halfdayin, halfdayout: v.halfdayout, workinghours: v.workinghours })
      }
    })
  }


  const setupRights = async (v) => {
    try {
      const response = await axios.post('/api/fetchRulesAll', {
        id: v.employee_id
      })
      if (response.data.response) {
        setFetchRules(response.data.data)
        setIsOpen3(true)
      }
      else {
        toast.error(response.data.message, {
          duration: 5000,
          position: 'bottom-right',
          style: {
            minWidth: '300px',
            minHeight: "50px"
          }
        })
      }
    } catch (error) {
      toast.error(error, {
        duration: 5000,
        position: 'bottom-right',
        style: {
          minWidth: '300px',
          minHeight: "50px"
        }
      })
    }
  }

  const closeModal2 = () => {
    setIsOpen2(false)
  }

  const closeModal3 = () => {
    setIsOpen3(false)
  }

  const setupedit = (v) => {
    setEditEmployee(v)
    setIsOpen2(true)
  }

  function handledata(e) {
    setEditEmployee({ ...editEmployee, [e.target.name]: e.target.value })
  }

  const editEmployeeSubmit = async (e) => {
    e.preventDefault()
    try {
      setButtonMessage("Submitting")
      const response = await axios.post('/api/editEmployeeSubmit', {
        body: editEmployee
      })
      if (response.data.success) {
        setButtonMessage('')
        setEditEmployee({
          ...editEmployee,
          employeecode: "",
          employeecnic: "",
          employeename: "",
          employeeemail: "",
          guardianname: "",
          dob: "",
          mobilenumber: "",
          residentialaddress: "",
          gender: "",
          martitalstatus: "",
          qualification: "",
          detailofqualification: "",
          machineid: "",
          machineserial: "",
          doa: "",
          dop: "",
          designation: "",
          department: "",
          branch: "",
          ait: "",
          lia: "",
          aot: "",
          eoa: "",
          remarks: "",
          userType_id: "",
          base_salary: "",
          incentive: "",
          conveyance: "",
          other_allowance: "",
          halfdayin: "",
          halfdayout: "",
          workinghours: "",
          bankaccount: "",
          employeebank: "",
          isActive: 1
        })
        fetchEmployeeList()
        closeModal2()
        toast.success(response.data.message, {
          duration: 5000,
          position: 'bottom-right',
          style: {
            borderBottom: "3px solid #61D345",
            minWidth: '300px',
            minHeight: "50px"
          }
        })
      } else {
        toast.error(response.data.error.code == "ER_DUP_ENTRY" ? "Id already exsists" : "Server Error", {
          duration: 5000,
          position: 'bottom-right',
          style: {
            minWidth: '300px',
            minHeight: "50px"
          }
        })
        setButtonMessage('')
        console.error('Failed to update details: ', response.data.error)
      }
    } catch (error) {
      setButtonMessage('')
      toast.error(error.response.data.error.code == "ER_DUP_ENTRY" ? "Id already exsists" : "Server Error", {
        duration: 5000,
        position: 'bottom-right',
        style: {
          minWidth: '300px',
          minHeight: "50px"
        }
      })
      console.error('Error changing employee details: ', error)
    }
  }

  function handledataIsActive(e) {
    if (e.target.checked) {
      setEditEmployee({ ...editEmployee, [e.target.name]: 1 })
    }
    else {
      setEditEmployee({ ...editEmployee, [e.target.name]: 0 })
    }
  }

  function handleRuleData(e, form) {
    if (e.target.checked) {
      if (e.target.name == "Allow_Admin") {
        let tempGrid = fetchRules.map((item, index) => item.Object_Name == form ? { ...item, [e.target.name]: 1, Allow_View: 1, Allow_Edit: 1, Allow_Add: 1, Allow_Delete: 1 } : item)
        setFetchRules(tempGrid)
      }
      else {
        let tempGrid = fetchRules.map((item, index) => item.Object_Name == form ? { ...item, [e.target.name]: 1 } : item)
        setFetchRules(tempGrid)
      }
    }
    else {
      if (e.target.name == "Allow_Admin") {
        let tempGrid = fetchRules.map((item, index) => item.Object_Name == form ? { ...item, [e.target.name]: 0, Allow_View: 0, Allow_Edit: 0, Allow_Add: 0, Allow_Delete: 0 } : item)
        setFetchRules(tempGrid)
      }
      else {
        let tempGrid = fetchRules.map((item, index) => item.Object_Name == form ? { ...item, [e.target.name]: 0 } : item)
        setFetchRules(tempGrid)
      }
    }
  }

  async function submitRules(e) {
    e.preventDefault()
    try {
      setButtonMessage("Submitting")
      const response = await axios.post('/api/submitRules', {
        body: fetchRules
      })
      if (response.data.success) {
        setButtonMessage('')
        closeModal3()
        toast.success(response.data.message, {
          duration: 5000,
          position: 'bottom-right',
          style: {
            borderBottom: "3px solid #61D345",
            minWidth: '300px',
            minHeight: "50px"
          }
        })
      } else {
        setButtonMessage('')
        toast.error(response.data.error, {
          duration: 5000,
          position: 'bottom-right',
          style: {
            minWidth: '300px',
            minHeight: "50px"
          }
        })
      }
    } catch (error) {
      setButtonMessage('')
      toast.error(error, {
        duration: 5000,
        position: 'bottom-right',
        style: {
          minWidth: '300px',
          minHeight: "50px"
        }
      })
    }
  }

  const handlePrevEmployee = () => {
    const currentIndex = newRows.findIndex((employee) => employee.employee_id === editEmployee.employee_id);
    if (currentIndex > 0) {
      setEditEmployee(newRows[currentIndex - 1]);
    }
  };

  const handleNextEmployee = () => {
    const currentIndex = newRows.findIndex((employee) => employee.employee_id === editEmployee.employee_id);
    if (currentIndex < newRows.length - 1) {
      setEditEmployee(newRows[currentIndex + 1]);
    }
  };

  // Memoize the table rows
  const tableRows = useMemo(() => {
    const rowsToRender = filterRows.length > 0 ? filterRows : rows;
    return rowsToRender.map((row, index) => {
      return (
        <tr key={index}>
          <td>
            <Checkbox
              checked={selectedEmployees.includes(row.employee_id)}
              onChange={() => onSelectEmployee(row.employee_id)}
            />
          </td>
          <td>{index + 1}</td>
        <td>{row.employee_id}</td>
        <td>{row.employeename}</td>
        <td>{row.guardianname}</td>
        <td>{row.machineid}</td>
        <td>{row.employeecnic}</td>
        <td>{row.mobilenumber}</td>
        <td>{row.designation}</td>
        <td>{row.departmentName}</td>
        <td>{row.branch_name}</td>
        <td>{row.doa}</td>
        <td>{capitalizeString(row.isActive == 1 ? "Active" : "Inactive")}</td>
          <td>
            <Button
              size="small"
              variant='outlined'
              onClick={() => setupedit(row)}
              sx={{ mr: 1 }}
            >
              Edit
            </Button>
            <Button
              size="small"
              variant='outlined'
              onClick={() => setupRights(row)}
            >
              Setup Rights
            </Button>
          </td>
        </tr>
      );
    });
  }, [filterRows, rows, selectedEmployees, onSelectEmployee]);

  return (
    <>
      <Card sx={{ p: 2 }}>
        {/* Header */}
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {icon}
            <Typography variant='h5'>{title}</Typography>
          </Box>
          
          {/* Search field */}
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <TextField
            className='search-input'
              placeholder="Search employees..."
              value={searchValue}
              onChange={(e) => onSearch(e.target.value)}
              sx={{ width: 300 }}
              InputProps={{
                endAdornment: searchValue && (
                  <IconButton size="small" onClick={onReset}>
                    <ClearIcon />
                  </IconButton>
                )
              }}
            />
            
            <Button 
              size="small" 
              variant='outlined'
              sx={{ height: 40, width: 140 }}
              // disabled={!newRules.length}
              onClick={openModal1}
            >
              Add {title}
            </Button>
            
            {title === "Employee" && (
              <Button 
                size="small" 
                variant='outlined'
                sx={{ height: 40, width: 140 }}
                disabled={selectedEmployees.length === 0}
                onClick={onIncrementClick}
              >
                Process Increment
              </Button>
            )}
          </Box>
        </Box>

        {/* Table */}
        <div className="table-container">
          <Box id="table-main">
            <table className='table table-bordered' style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th>
                    <Checkbox
                      checked={selectedEmployees.length > 0 && selectedEmployees.length === rows.length}
                      indeterminate={selectedEmployees.length > 0 && selectedEmployees.length < rows.length}
                      onChange={onSelectAll}
                    />
                  </th>
                  {columns.map((column, i) => (
                    <th key={i}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableRows}
              </tbody>
            </table>
          </Box>
        </div>
      </Card>
      <Dialog
        open={modalIsOpen2}
        PaperProps={{
          component: 'form',
          sx: {
            width: { xs: '100%', sm: '70%' },
          },
        }}
      >
        <DialogTitle sx={{ fontSize: { xs: "2rem", md: "2.5rem" } }} >
          {`Edit Employee`}
          <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
            <Button
              size="small"
              variant='outlined'
              sx={{ height: 25, width: 60, marginRight: 1 }}
              onClick={() => handlePrevEmployee()}
            >
              <ArrowBackIcon />
            </Button>
            <Button
              size="small"
              variant='outlined'
              sx={{ height: 25, width: 60 }}
              onClick={() => handleNextEmployee()}
            >
              <ArrowForwardIcon />
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent sx={{
          '& .MuiInputBase-input': { fontSize: '1.5rem' },
          '& .MuiInputLabel-root': { fontSize: '1.25rem' },
        }}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField autoFocus required fullWidth variant="standard"
                id="employeecode"
                name="employeecode"
                label="Emloyee Code"
                type="number"
                value={editEmployee.employeecode}
                onChange={handledata}
              />
              <MaskedTextField required fullWidth variant="standard"
                id="employeecnic"
                name="employeecnic"
                label="Emloyee CNIC"
                type="text"
                value={editEmployee.employeecnic}
                onChange={handledata}
                mask="00000-0000000-0"
                placeholder="00000-0000000-0"
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="employeename"
                name="employeename"
                label="Emloyee Name"
                type="text"
                value={editEmployee.employeename}
                onChange={handledata}
              />
              <TextField fullWidth variant="standard"
                id="guardianname"
                name="guardianname"
                label="Guardian Name"
                type="text"
                value={editEmployee.guardianname}
                onChange={handledata} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="machineid"
                name="machineid"
                label="Machine ID"
                type="text"
                value={editEmployee.machineid}
                onChange={handledata}
              />

              <TextField required fullWidth variant="standard"
                id="machineserial"
                name="machineserial"
                label="Machine Serial"
                type="text"
                value={editEmployee.machineserial}
                onChange={handledata}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="dob"
                name="dob"
                label="Date of Birth"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    max: '2050-12-31',
                    min: '1900-01-01',
                    maxLength: 4,
                  },
                }}
                value={editEmployee.dob}
                onChange={handledata}
              />
              <MaskedTextField fullWidth required variant="standard"
                id="mobilenumber"
                name="mobilenumber"
                label="Mobile Number"
                type="text"
                value={editEmployee.mobilenumber}
                onChange={handledata}
                mask="0000-0000000"
                placeholder="0300-1234567"
              />
            </Box>
            <TextField required fullWidth variant="standard"
              id="residentialaddress"
              name="residentialaddress"
              label="Residential Address"
              type="text"
              value={editEmployee.residentialaddress}
              onChange={handledata}
            />
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
              <TextField required fullWidth variant="standard"
                id="employeeemail"
                name="employeeemail"
                label="Employee Email"
                type="text"
                value={editEmployee.employeeemail}
                onChange={handledata}
              />
              <FormControlLabel
                label="Active"
                control={
                  <Switch
                    id="isActive"
                    name="isActive"
                    onChange={handledataIsActive}
                    checked={editEmployee.isActive == 1 ? true : false}
                  />
                }
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="gender"
                name="gender"
                label="Gender"
                value={editEmployee.gender?.toLowerCase()}
                onChange={handledata}
                select>
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="transgender">Transgender</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </TextField>
              <TextField required fullWidth variant="standard"
                id="martitalstatus"
                name="martitalstatus"
                label="Marital Status"
                value={editEmployee.martitalstatus?.toLowerCase()}
                onChange={handledata}
                select>
                <MenuItem value="married">Married</MenuItem>
                <MenuItem value="unmarried">Un Married</MenuItem>
                <MenuItem value="separated">Separated</MenuItem>
                <MenuItem value="widow">Widow</MenuItem>
                <MenuItem value="widower">Widower</MenuItem>
              </TextField>
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="qualification"
                name="qualification"
                label="Qualification"
                value={editEmployee.qualification?.toLowerCase()}
                onChange={handledata}
                select>
                <MenuItem value="middle">Middle</MenuItem>
                <MenuItem value="matric">Matric</MenuItem>
                <MenuItem value="intermediate">Intermediate</MenuItem>
                <MenuItem value="graduation">Graduation</MenuItem>
                <MenuItem value="master">Masters</MenuItem>
                <MenuItem value="phd">Phd</MenuItem>
                <MenuItem value="other">Others</MenuItem>
              </TextField>
              <TextField fullWidth variant="standard"
                id="detailofqualification"
                name="detailofqualification"
                label="Detail of Qualification"
                type="text"
                value={editEmployee.detailofqualification}
                onChange={handledata}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="doa"
                name="doa"
                label="Appointment Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    max: '2050-12-31',
                    min: '1900-01-01',
                    maxLength: 4,
                  },
                }}
                value={editEmployee.doa}
                onChange={handledata}
              />
              <TextField required fullWidth variant="standard"
                id="dop"
                name="dop"
                label="Probation End Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    max: '2050-12-31',
                    min: '1900-01-01',
                    maxLength: 4,
                  },
                }}
                value={editEmployee.dop}
                onChange={handledata}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="designation"
                name="designation_id"
                label="Designation"
                value={editEmployee.designation_id}
                onChange={handledata}
                select>
                {
                  designationList.map((v, i) => {
                    return <MenuItem value={v.designation_id}>{v.designation}</MenuItem>
                  })
                }
              </TextField>
              <TextField required fullWidth variant="standard"
                id="department"
                name="department"
                label="Department"
                value={editEmployee.department}
                onChange={handledataDept}
                select>
                {
                  departmentList.map((v, i) => {
                    return <MenuItem value={v.department_id}>{v.departmentName}</MenuItem>
                  })
                }
              </TextField>
              <TextField required fullWidth variant="standard"
                id="branch"
                name="branch"
                label="Branch"
                value={editEmployee.branch}
                onChange={handledata}
                select>
                {
                  branchList.map((v, i) => {
                    return <MenuItem value={v.branch_id}>{v.branch_name}</MenuItem>
                  })
                }
              </TextField>
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="ait"
                name="ait"
                label="Applied In Time"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={editEmployee.ait}
                onChange={handledata}
              />
              <TextField required fullWidth variant="standard"
                id="lia"
                name="lia"
                label="Late In Allowed"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={editEmployee.lia}
                onChange={handledata}
              />
              <TextField required fullWidth variant="standard"
                id="halfdayin"
                name="halfdayin"
                label="Half Day In Time:"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={editEmployee.halfdayin}
                onChange={handledata}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="aot"
                name="aot"
                label="Applied Out Time"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={editEmployee.aot}
                onChange={handledata}
              />
              <TextField required fullWidth variant="standard"
                id="eoa"
                name="eoa"
                label="Early Out Allowed"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={editEmployee.eoa}
                onChange={handledata}
              />
              <TextField required fullWidth variant="standard"
                id="halfdayout"
                name="halfdayout"
                label="Half Day Out Time:"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={editEmployee.halfdayout}
                onChange={handledata}
              />
            </Box>

            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="employeebank"
                name="employeebank"
                label="Bank"
                type="text"
                value={editEmployee.employeebank}
                onChange={handledata}
              />
              <TextField required fullWidth variant="standard"
                id="bankaccount"
                name="bankaccount"
                label="Account"
                type="number"
                value={editEmployee.bankaccount}
                onChange={handledata}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="base_salary"
                name="base_salary"
                label="Base Salary"
                type="number"
                value={editEmployee.base_salary}
                onChange={handledata}
              />
              <TextField required fullWidth variant="standard"
                id="incentive"
                name="incentive"
                label="Incentive allowance"
                type="number"
                value={editEmployee.incentive}
                onChange={handledata}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="conveyance"
                name="conveyance"
                label="Conveyance allowance"
                type="number"
                value={editEmployee.conveyance}
                onChange={handledata}
              />
              <TextField required fullWidth variant="standard"
                id="other_allowance"
                name="other_allowance"
                label="Other allowance"
                type="number"
                value={editEmployee.other_allowance}
                onChange={handledata}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="workinghours"
                name="workinghours"
                label="Working Hours:"
                value={editEmployee.workinghours}
                onChange={handledata}
                select
              >
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="6">6</MenuItem>
                <MenuItem value="7">7</MenuItem>
                <MenuItem value="8">8</MenuItem>
                <MenuItem value="9">9</MenuItem>
                <MenuItem value="10">10</MenuItem>
                <MenuItem value="11">11</MenuItem>
                <MenuItem value="12">12</MenuItem>
              </TextField>
              <TextField fullWidth variant="standard"
                id="remarks"
                name="remarks"
                label="Remarks & Other Notes"
                type="text"
                value={editEmployee.remarks}
                onChange={handledata} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeModal2}
            disabled={buttonMessage !== '' ? true : false}
          >
            Cancel
          </Button>
          <Button type="submit"
            onClick={editEmployeeSubmit}
            disabled={buttonMessage !== '' ? true : false}
          >
            {buttonMessage !== '' ? buttonMessage : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={modalIsOpen3}
        onClose={closeModal3}
        PaperProps={{
          component: 'form',
          sx: {
            width: { xs: '100%', sm: '70%' },
          },
        }}
      >
        <DialogTitle sx={{ fontSize: { xs: "2rem", md: "2.5rem" } }} >{`Employee Rights`}</DialogTitle>
        <DialogContent sx={{
          '& .MuiInputBase-input': { fontSize: '1.5rem' },
          '& .MuiInputLabel-root': { fontSize: '1.25rem' },
          overflow: "hidden"
        }}>
          <table className="table table-bordered" id="table-main">
            <tr >
              <td style={{ textAlign: "left" }}>
                Form Name
              </td>
              <td style={{ width: 82 }}>
                Admin
              </td>
              <td style={{ width: 82 }}>
                View
              </td>
              <td style={{ width: 82 }}>
                Add
              </td>
              <td style={{ width: 82 }}>
                Edit
              </td>
              <td style={{ width: 82 }}>
                Delete
              </td>
            </tr>
          </table>
          <Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row", height: 500, overflow: "scroll", paddingBottom: 100 }, gap: 2 }} >
              <table className="table table-bordered" id="table-main" >
                {
                  fetchRules.map((v, i) => {
                    return <tr>
                      <td style={{ textAlign: "left" }}>{v.Object_Caption}</td>
                      <td style={{ maxWidth: 70 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              id="Allow_Admin"
                              name="Allow_Admin"
                              onChange={(e) => handleRuleData(e, v.Object_Name)}
                              checked={v.Allow_Admin == 1 ? true : false}
                            />
                          }
                        />
                      </td>
                      <td style={{ maxWidth: 70 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              id="Allow_View"
                              name="Allow_View"
                              onChange={(e) => handleRuleData(e, v.Object_Name)}
                              checked={v.Allow_View == 1 ? true : false}
                            />
                          }
                        />
                      </td>
                      <td style={{ maxWidth: 70 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              id="Allow_Add"
                              name="Allow_Add"
                              onChange={(e) => handleRuleData(e, v.Object_Name)}
                              checked={v.Allow_Add == 1 ? true : false}
                            />
                          }
                        />
                      </td>
                      <td style={{ maxWidth: 70 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              id="Allow_Edit"
                              name="Allow_Edit"
                              onChange={(e) => handleRuleData(e, v.Object_Name)}
                              checked={v.Allow_Edit == 1 ? true : false}
                            />
                          }
                        />
                      </td>
                      <td style={{ maxWidth: 70 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              id="Allow_Delete"
                              name="Allow_Delete"
                              onChange={(e) => handleRuleData(e, v.Object_Name)}
                              checked={v.Allow_Delete == 1 ? true : false}
                            />
                          }
                        />
                      </td>
                    </tr>
                  })
                }
              </table>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeModal3}
            disabled={buttonMessage !== '' ? true : false}
          >
            Cancel
          </Button>
          <Button type="submit"
            onClick={submitRules}
            disabled={buttonMessage !== '' ? true : false}
          >
            {buttonMessage !== '' ? buttonMessage : "Submit"}
          </Button>
        </DialogActions>
      </Dialog >
      <Toaster />
    </>
  )
})

export default DataTable