import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Box
} from '@mui/material';
import axios from 'axios';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie'


const SalaryIncrementModal = ({ open, onClose, selectedEmployees, onSuccess }) => {
    const [incrementType, setIncrementType] = useState('percentage');
    const [incrementValue, setIncrementValue] = useState('');
    const [incrementDate, setIncrementDate] = useState(new Date());
    const [remarks, setRemarks] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!incrementValue || incrementValue <= 0) {
            toast.error('Please enter a valid increment value');
            return;
        }

        setIsSubmitting(true);

        try {
            console.log(incrementDate)
            const response = await axios.post('/api/incrementSalary', {
                body: {
                    company_id: Cookies.get('userInfo'),
                    employee_ids: selectedEmployees,
                    increment_type: incrementType,
                    increment_value: parseFloat(incrementValue),
                    increment_date: incrementDate,
                    remarks
                }
            });

            if (response.data.success) {
                toast.success('Salary increments processed successfully');
                onSuccess();
                onClose();
            } else {
                toast.error('Failed to process salary increments');
            }
        } catch (error) {
            console.error('Error processing increments:', error);
            toast.error('Error processing salary increments');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Salary Increment</DialogTitle>
            <DialogContent>
                <Box component="form" sx={{ mt: 2 }} spacing={3}>
                    <FormControl component="fieldset" fullWidth sx={{ mb: 2 }}>
                        <RadioGroup
                            row
                            value={incrementType}
                            onChange={(e) => setIncrementType(e.target.value)}
                        >
                            <FormControlLabel
                                value="percentage"
                                control={<Radio />}
                                label="Percentage (%)"
                            />
                            <FormControlLabel
                                value="amount"
                                control={<Radio />}
                                label="Fixed Amount"
                            />
                        </RadioGroup>
                    </FormControl>

                    <TextField
                        fullWidth
                        label={incrementType === 'percentage' ? 'Percentage' : 'Amount'}
                        type="number"
                        value={incrementValue}
                        onChange={(e) => setIncrementValue(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <TextField required fullWidth variant="standard"
                        id="dob"
                        name="dob"
                        label="Increment Date"
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            inputProps: {
                                max: '2050-12-31',
                                min: '1900-01-01',
                                maxLength: 4, // Restrict input length to 4 digits
                            },
                        }}
                        value={incrementDate}
                        onChange={(e) => setIncrementDate(e.target.value)}
                    />


                    <TextField
                        fullWidth
                        label="Remarks"
                        multiline
                        rows={4}
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Processing...' : 'Submit'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SalaryIncrementModal; 