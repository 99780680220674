import React, { useState, useEffect } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import { Box, Card, Typography, TextField, Button, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, Tabs, Tab } from '@mui/material';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import toast, { Toaster } from 'react-hot-toast';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import moment from 'moment';
import { capitalizeValues } from '../utils/formatters';

const Loan = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [employeeList, setEmployeeList] = useState([]);
    const [history, setHistory] = useState([]);
    const [repaymentHistory, setRepaymentHistory] = useState([]);
    const [filterArr, setFilterArr] = useState([]);
    const [editEntry, setEditEntry] = useState({company_id: Cookies.get('userInfo'),
        issue_date:moment().format('YYYY-MM-DD'),
        repayment_start_date:moment().format('YYYY-MM-DD'),
        repayment_end_date:moment().format('YYYY-MM-DD')
    });
    const [buttonMessage, setButtonMessage] = useState('');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [loanIdToDelete, setLoanIdToDelete] = useState(null);

    function filterFunc(e) {
        let temp = history.filter((v, i) => {
            return v.employeename.toLowerCase().indexOf(e.toLowerCase()) !== -1
        })
        setFilterArr(temp)
    }
    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const fetchEmployeeList = async () => {
        try {
            const response = await axios.post('/api/fetchEmployeeList', {
                id: Cookies.get('userInfo'),
                status: 'active'
            });
            if (response.data.response) {
                setEmployeeList(capitalizeValues(response.data.data));
            } else {
                console.error('Failed to fetch employee list: ', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching employee list: ', error);
        }
    };

    const fetchLoanAdvanceHistory = async () => {
        setHistory([{}]);
        try {
            const response = await axios.post('/api/funcLoanAdvanceHistory', {
                id: Cookies.get('userInfo')
            });
            if (response.data.response) {
                setHistory(capitalizeValues(response.data.data));
            } else {
                console.error('Failed to fetch history: ', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching history: ', error);
        }
    };

    const fetchRepaymentHistory = async () => {
        setRepaymentHistory([{}]);
        try {
            const response = await axios.post('/api/fetchRepaymentHistory', {
                id: Cookies.get('userInfo')
            });
            if (response.data.response) {
                setRepaymentHistory(capitalizeValues(response.data.data));
            } else {
                console.error('Failed to fetch Repayment history: ', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching Repayment history: ', error);
        }
    };

    useEffect(() => {
        fetchEmployeeList();
        fetchLoanAdvanceHistory();
        fetchRepaymentHistory();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditEntry({
            ...editEntry,
            [name]: value,
        });
    };

    const handleInputEdit = (e) => {
        const { name, value } = e.target;
        setEditEntry({
            ...editEntry,
            [name]: value,
        });
    };

    const addOrUpdateAmount = async (e) => {
        e.preventDefault();
        if (Number(editEntry.monthly_deduction) >= Number(editEntry.loan_amount)) {
            toast.error("Monthly deduction must be less than loan amount", {
                duration: 5000,
                position: 'bottom-right',
                style: {
                    minWidth: '300px',
                    minHeight: "50px"
                }
            });
            return;
        }

        if ((!editEntry.employee_id || !editEntry.type || !editEntry.loan_amount || !editEntry.issue_date || !editEntry.repayment_start_date || !editEntry.repayment_end_date || !editEntry.monthly_deduction)) {
            toast.error("All fields are required", {
                duration: 5000,
                position: 'bottom-right',
                style: {
                    minWidth: '300px',
                    minHeight: "50px"
                }
            });
            return;
        }

        try {
            console.log("editEntry", editEntry);
            setButtonMessage("Adding");
            const response = await axios.post(`${editEntry?.loan_id ? "/api/updateLoan" : "/api/addLoan"}`, {
                body: editEntry
            });
            if (response.data.success) {
                setButtonMessage('');
                toast.success(response.data.message, {
                    duration: 5000,
                    position: 'bottom-right',
                    style: {
                        borderBottom: "3px solid #61D345",
                        minWidth: '300px',
                        minHeight: "50px"
                    }
                });
                setIsOpen(false);
                fetchLoanAdvanceHistory();
            } else {
                setButtonMessage('');
                console.error('Failed to add loan/advance: ', response.data.error);
            }
        } catch (error) {
            setButtonMessage('');
            console.error('Error adding loan/advance: ', error);
        }
    };

    const editEntrySubmit = async (e) => {
        e.preventDefault();
        try {
            setButtonMessage("Submitting");
            const response = await axios.post('/api/editEntrySubmit', {
                body: editEntry,
                modified_by: Cookies.get("userInfo")
            });
            if (response.data.success) {
                setButtonMessage('');
                setEditEntry({company_id: Cookies.get('userInfo'),
                    issue_date:moment().format('YYYY-MM-DD'),
                    repayment_start_date:moment().format('YYYY-MM-DD'),
                    repayment_end_date:moment().format('YYYY-MM-DD')
                });
                setIsOpen(false);
                toast.success(response.data.message, {
                    duration: 5000,
                    position: 'bottom-right',
                    style: {
                        borderBottom: "3px solid #61D345",
                        minWidth: '300px',
                        minHeight: "50px"
                    }
                });
                fetchLoanAdvanceHistory();
            } else {
                setButtonMessage('');
                console.error('Failed to update details: ', response.data.error);
            }
        } catch (error) {
            setButtonMessage('');
            console.error('Error updating details: ', error);
        }
    };

    const openEditDialog = (entry) => {
        setEditEntry(entry);
        setIsOpen(true);
    };

    const openAddDialog = () => {
        setEditEntry({company_id: Cookies.get('userInfo'),
            issue_date:moment().format('YYYY-MM-DD'),
            repayment_start_date:moment().format('YYYY-MM-DD'),
            repayment_end_date:moment().format('YYYY-MM-DD')
        });
        setIsOpen(true);
    };

    const deleteEntry = async (loanId) => {
        try {
            const response = await axios.post('/api/deleteLoan', {
                loan_id: loanId,
                modified_by: Cookies.get("userInfo")
            });
            if (response.data.success) {
                toast.success(response.data.message, {
                    duration: 5000,
                    position: 'bottom-right',
                    style: {
                        borderBottom: "3px solid #61D345",
                        minWidth: '300px',
                        minHeight: "50px"
                    }
                });
                fetchLoanAdvanceHistory();
            } else {
                console.error('Failed to delete loan: ', response.data.error);
            }
        } catch (error) {
            console.error('Error deleting loan: ', error);
        } finally {

        }
    };

    const handleDeleteEntry = (loanId) => {
        setLoanIdToDelete(loanId);
        setConfirmDeleteOpen(true);
    };

    const confirmDelete = async () => {
        if (loanIdToDelete) {
            await deleteEntry(loanIdToDelete);
        }
        setConfirmDeleteOpen(false);
    };

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: "column", height: "auto", minWidth: 1, padding: 1 }}>
                <Box sx={{ display: { xs: 'block', sm: 'flex' }, justifyContent: "space-between", }}>
                    <Box sx={{ display: 'flex' }}>
                        <Card sx={{
                            margin: 0.5, mr: 1, padding: 1, paddingTop: 1, display: "flex", justifyContent: "center", alignItems: "center",
                        }}><CreditScoreIcon fontSize="large" /></Card>
                        <Typography sx={{ mt: "auto", mb: "auto", fontSize: { xs: '1.5rem', sm: "2rem" } }}>Advance/Loan</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1, mt: 1, marginBottom: { xs: '10px', sm: "0px" } }}>
                        <TextField
                            style={{ marginRight: 5 }}
                            onChange={(e) => filterFunc(e.target.value)}
                            label={`Search via Employee`}
                            type="search"
                            size='small'
                            sx={{ width: { sx: "100%" }, }}
                        />
                        <Button
                            size="small" variant='outlined' sx={{ height: 40, width: 120 }}
                            onClick={openAddDialog}
                           
                        >
                            Add Advance/Loan
                        </Button>
                    </Box>
                </Box>
                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="loan and repayment tabs">
                    <Tab label="Advance/Loan" />
                    <Tab label="Repayments" />
                </Tabs>
                {tabIndex === 0 && (
                    <div>
                        <table className="table table-bordered" id="table-main">
                            <thead>
                                <tr>
                                    <th>S. No</th>
                                    <th>Emp. ID</th>
                                    <th>Emp. Name</th>
                                    <th>Amount</th>
                                    <th>Type</th>
                                    <th>Issue Date</th>
                                    <th>Monthly Deduction</th>
                                    <th>Repayment Start Date</th>
                                    <th>Repayment End Date</th>
                                    <th>Notes</th>
                                    <th>Repayment Status</th>
                                    <th>Repaid Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(filterArr.length ? filterArr : history).map((v, i) => (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{v.employee_id}</td>
                                        <td>{v.employeename}</td>
                                        <td>{v.loan_amount}</td>
                                        <td>{v.type}</td>
                                        <td>{moment(v.issue_date).format('YYYY-MM-DD')}</td>
                                        <td>{v.monthly_deduction}</td>
                                        <td>{moment(v.repayment_start_date).format('YYYY-MM-DD')}</td>
                                        <td>{moment(v.repayment_end_date).format('YYYY-MM-DD')}</td>
                                        <td>{v.notes}</td>
                                        <td>{v.status === 1 ? "Yes" : "No"}</td>
                                        <td>{v.repaid_date}</td>
                                        <td>
                                            {!v.status && <>
                                                <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => openEditDialog(v)}
                                            >
                                                Edit
                                            </Button>
                                                <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => handleDeleteEntry(v.loan_id)}
                                            >
                                                Delete
                                            </Button>
                                            </>
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                {tabIndex === 1 && (
                    <div>
                        <table className="table table-bordered" id="table-main">
                            <thead>
                                <tr>
                                    <th>S. No</th>
                                    <th>Emp. ID</th>
                                    <th>Emp. Name</th>
                                    <th>Repayment Amount</th>
                                    <th>Repayment Date</th>
                                    <th>Type</th>
                                    <th>Modified Date</th>
                                    <th>Notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {repaymentHistory.map((v, i) => (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{v.employee_id}</td>
                                        <td>{v.employeename}</td>
                                        <td>{v.repayment_amount}</td>
                                        <td>{v.repayment_date  ?moment(v.repayment_date).format('YYYY-MM-DD'):""}</td>
                                        <td>{v.type}</td>
                                        <td>{v.modified_date  ? moment(v.modified_date).format('YYYY-MM-DD'):""}</td>
                                        <td>{v.notes}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </Box>
            <Toaster />
            <Dialog
                open={modalIsOpen}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    component: 'form',
                    sx: {
                        width: { xs: '100%', sm: '70%' },
                    },
                }}
            >
                <DialogTitle sx={{ fontSize: { xs: "2rem", md: "2.5rem" } }} >{editEntry.loan_id ? `Edit Entry` : `Add Entry`}</DialogTitle>
                <DialogContent sx={{
                    '& .MuiInputBase-input': { fontSize: '1.5rem' },
                    '& .MuiInputLabel-root': { fontSize: '1.25rem' },
                }}>
                    <Box display="flex" flexDirection="column" gap={2}>
                           <TextField fullWidth variant="standard"
                                                        id="employee_id"
                                                        name="employee_id"
                                                        label="Employee Name | ID:"
                                                        value={editEntry.employee_id}
                                                        onChange={handleInputChange}
                                                        select
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    >
                                                        {employeeList.map((v, i) => {
                                                            return <MenuItem key={i} value={v.employee_id}>{`${v.employeename} | ${v.employee_id}`}</MenuItem>
                                                        })}
                                                    </TextField>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">Request Type:</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                id="type"
                                name="type"
                                onChange={handleInputEdit}
                                value={editEntry.type || ''}
                            >
                                <Box display="flex" flexDirection="row" gap={2}>
                                    <FormControlLabel value="advance" control={<Radio />} label="Advance" />
                                    <FormControlLabel value="loan" control={<Radio />} label="Loan" />
                                </Box>
                            </RadioGroup>
                        </FormControl>
                        <TextField fullWidth variant="standard"
                            id="loan_amount"
                            type="number"
                            name="loan_amount"
                            label="Amount:"
                            value={editEntry.loan_amount || ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleInputEdit}
                        />
                        <TextField variant="standard"
                            id="issue_date"
                            name="issue_date"
                            label="Repayment Date:"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={moment(editEntry.issue_date).format('YYYY-MM-DD') || ''}
                            onChange={handleInputEdit}
                        />
                        <TextField fullWidth variant="standard"
                            id="notes"
                            name="notes"
                            label="Notes"
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={editEntry.notes || ''}
                            onChange={handleInputEdit}
                        />
                        <TextField fullWidth variant="standard"
                            id="repayment_start_date"
                            name="repayment_start_date"
                            label="Repayment Start Date:"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={moment(editEntry.repayment_start_date).format('YYYY-MM-DD') || ''}
                            onChange={handleInputEdit}
                            disabled={editEntry.type === "Advance"}
                        />
                        <TextField fullWidth variant="standard"
                            id="repayment_end_date"
                            name="repayment_end_date"
                            label="Repayment End Date:"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={moment(editEntry.repayment_end_date).format('YYYY-MM-DD') || ''}
                            onChange={handleInputEdit}
                            disabled={editEntry.type === "Advance"}
                        />
                        <TextField fullWidth variant="standard"
                            id="monthly_deduction"
                            type="number"
                            name="monthly_deduction"
                            label="Monthly Deduction Amount:"
                            value={editEntry.monthly_deduction || ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleInputEdit}
                            disabled={editEntry.type === "Advance"}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {setIsOpen(false) 
                        setEditEntry({company_id: Cookies.get('userInfo'),
                            issue_date:moment().format('YYYY-MM-DD'),
                            repayment_start_date:moment().format('YYYY-MM-DD'),
                            repayment_end_date:moment().format('YYYY-MM-DD')
                        })}
                        }
                        disabled={buttonMessage !== ''}
                    >
                        Cancel
                    </Button>
                    <Button type="submit"
                        onClick={addOrUpdateAmount}
                        disabled={buttonMessage !== ''}
                    >
                        {buttonMessage !== '' ? buttonMessage : editEntry.loan_id ? "Update" : "Submit"}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={confirmDeleteOpen}
                onClose={() => setConfirmDeleteOpen(false)}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this loan?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDeleteOpen(false)} color="primary">
                        No
                    </Button>
                    <Button onClick={confirmDelete} color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Loan;