import React, { useState, useEffect } from 'react'
import axios from "axios"
import Cookies from 'js-cookie'
import { Box, Button, TextField, Card, Typography, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import MaskedTextField from 'react-masked-mui-textfield'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import toast, { Toaster } from 'react-hot-toast'
import { capitalizeValues } from '../utils/formatters'

const Branch = () => {

    const [modalIsOpen1, setIsOpen1] = useState(false)
    const [filterArr, setFilterArr] = useState([])
    const [ErrorAlertOpenmobilenumber, setErrorAlertOpenmobilenumber] = useState(false)
    const [ErrorAlertOpenemail, setErrorAlertOpenemail] = useState(false)
    const [editBranch, setEditBranch] = useState({})
    const [modalIsOpen2, setIsOpen2] = useState(false)
    const [rules, setRules] = useState([])

    const [buttonMessage, setButtonMessage] = useState('')

    const openModal1 = () => {
        setIsOpen1(true)
    }

    const setupedit = (v) => {
        setEditBranch(v)
        setIsOpen2(true)
    }

    function handledata(e) {
        setEditBranch({ ...editBranch, [e.target.name]: e.target.value })
    }

    const closeModal2 = () => {
        setIsOpen2(false)
    }

    const editBranchSubmit = async (e) => {
        e.preventDefault()
        try {
            setButtonMessage("Submitting")
            const response = await axios.post('/api/editBranchSubmit', {
                body: editBranch
            })
            if (response.data.success) {
                setButtonMessage('')
                toast.success(response.data.message, {
                    duration: 5000,
                    position: 'bottom-right',
                    style: {
                        borderBottom: "3px solid #61D345",
                        minWidth: '300px',
                        minHeight: "50px"
                    }
                })
                fetchBranchList()
                closeModal2()
            } else {
                setButtonMessage('')
                console.error("Failed to edit branch: ", response.data.error)
            }
        } catch (error) {
            setButtonMessage('')
            console.error('Error editing branch: ', error)
        }
    }

    async function deletefunc(v) {
        try {
            const response = await axios.post('/api/deleteBranch', {
                body: v
            })
            if (response.data.response) {
                fetchBranchList()
                toast.success("Successfully deleted", {
                    duration: 5000,
                    position: 'bottom-right',
                    style: {
                        borderBottom: "3px solid #61D345",
                        minWidth: '300px',
                        minHeight: "50px"
                    }
                })
            }
            else {
                console.error('Failed to delete branch: ', response.data.error)
            }
        } catch (error) {
            console.error('Error deleting branch: ', error)
        }
    }

    const closeModal1 = () => {
        setBranchData({
            ...branchData,
            branch_name: '',
            location: '',
            contact_person: '',
            contact_phone: '',
            contact_email: '',
        })
        setIsOpen1(false)
    }

    const [branchData, setBranchData] = useState({
        company_id: Cookies.get('userInfo'),
        branch_name: '',
        location: '',
        contact_person: '',
        contact_phone: '',
        contact_email: '',
    })

    const [branchList, setBranchList] = useState([])

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setBranchData({
            ...branchData,
            [e.target.name]: e.target.value
        })
    }

    const addBranch = async (e) => {
        e.preventDefault()
        if (branchData.branch_name === "" || branchData.location === "" || branchData.contact_person === "" || branchData.contact_email === "" || branchData.contact_phone === "" || branchData.contact_phone < 12) {
            toast.error("All fields are required", {
                duration: 5000,
                position: 'bottom-right',
                style: {
                    minWidth: '300px',
                    minHeight: "50px"
                }
            })
        } else {
            try {
                setButtonMessage("Adding")
                const response = await axios.post('/api/addBranch', {
                    body: branchData
                })
                if (response.data.success) {
                    setButtonMessage('')
                    toast.success(response.data.message, {
                        duration: 5000,
                        position: 'bottom-right',
                        style: {
                            borderBottom: "3px solid #61D345",
                            minWidth: '300px',
                            minHeight: "50px"
                        }
                    })
                    closeModal1()
                    setBranchData({
                        ...branchData,
                        branch_name: '',
                        location: '',
                        contact_person: '',
                        contact_phone: '',
                        contact_email: '',
                    })
                    fetchBranchList()
                } else {
                    setButtonMessage('')
                    console.error('Failed to add branch: ', response.data.error)
                }
            } catch (error) {
                setButtonMessage('')
                console.error('Error adding branch: ', error)
            }
        }
    }

    const fetchBranchList = async () => {
        try {
            const response = await axios.post('/api/fetchBranchList', {
                id: Cookies.get('userInfo')
            })
            if (response.data.response) {
                setBranchList(capitalizeValues(response.data.data))
            }
            else {
                console.error('Failed to fetch branch list: ', response.data.error)
            }
        } catch (error) {
            console.error('Error fetching branch list: ', error)
        }
    }

    const fetchRules = async () => {
        let id = Cookies.get('eid')
        let userInfo = Cookies.get('userInfo')
        if (id) {
            try {
                const response = await axios.post('/api/fetchRules', {
                    id: id,
                    module: "frm_Branch_List"
                })
                if (response.data.response) {
                    setRules(response.data.data)
                }
                else {
                    console.error('Failed to fetch rules: ', response.data.error)
                }
            } catch (error) {
                console.error('Error fetching rules: ', error)
            }
        }
        else {
            try {
                const response = await axios.post('/api/fetchRules', {
                    userInfo: userInfo,
                })
                if (response.data.response) {
                    setRules(response.data.data)
                }
                else {
                    console.error('Failed to fetch rules: ', response.data.error)
                }
            } catch (error) {
                console.error('Error fetching rules: ', error)
            }
        }
    }

    useEffect(() => {
        fetchBranchList()
        fetchRules()
    }, [])

    function filterFunc(e) {
        let temp = branchList.filter((v, i) => {
            return v.branch_name.toLowerCase().indexOf(e.toLowerCase()) !== -1
        })
        setFilterArr(temp)
    }

    function handleBlurmobilenumber() {
        if (branchData.contact_phone === "" || branchData.contact_phone.length < 12) {
            setErrorAlertOpenmobilenumber(true)
        } else {
            setErrorAlertOpenmobilenumber(false)
        }
    }

    function handleBluremail() {
        var emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (emailPattern.test(branchData.contact_email)) {
            setErrorAlertOpenemail(false)
        } else {
            setErrorAlertOpenemail(true)
        }
    }

    return (
        <>
            {
                rules.length > 0
                    ?
                    (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1 || rules[0].Allow_View == 1)
                        ?
                        <Box sx={{ display: 'flex', flexDirection: "column", height: "auto", minWidth: 1, padding: 1 }}>
                            <Box sx={{ display: { xs: 'block', sm: 'flex' }, justifyContent: "space-between", }}>
                                <Box sx={{ display: 'flex' }}>
                                    <Card sx={{
                                        margin: 0.5, mr: 1, padding: 1, paddingTop: 1, display: "flex", justifyContent: "center", alignItems: "center",
                                    }}>{<AccountTreeIcon fontSize="large" />}</Card>
                                    <Typography sx={{ mt: "auto", mb: "auto", fontSize: { xs: '1.5rem', sm: "2rem" } }}>Branches</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1, mt: 1, marginBottom: { xs: '10px', sm: "0px" } }}>
                                    <TextField
                                        label={`Search Branch`}
                                        type="search"
                                        size='small'
                                        sx={{ width: { sx: "100%" }, }}
                                        style={{ marginRight: 5 }}
                                        onChange={(e) => filterFunc(e.target.value)}
                                    />
                                    <Button
                                        onClick={() => openModal1()}
                                        size="small" variant='outlined' sx={{ height: 40, width: 120 }}
                                        disabled={
                                            rules.length > 0
                                                ?
                                                (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                    ?
                                                    false
                                                    :
                                                    rules[0].Allow_Add == 1
                                                        ?
                                                        false
                                                        :
                                                        true
                                                :
                                                true
                                        }
                                    >
                                        Add Branch
                                    </Button>
                                </Box>
                            </Box>

                            <table className="table table-bordered" id="table-main">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>S. No</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Branch ID</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Branch Name</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Location</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Contact Person</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Contact Email</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Contact Phone</th>
                                        <th scope="col" style={{ fontWeight: "bold", padding: 2, verticalAlign: "middle" }}>Action</th>
                                    </tr>
                                </thead>
                                {
                                    filterArr.length
                                        ?
                                        filterArr.map((v2, i2) => {
                                            return <>
                                                <tbody>
                                                    <tr key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                                        <th style={{ padding: 2, textAlign: "center", verticalAlign: "middle" }}>{(i2 + 1).toString().length < 2 ? "0" + (i2 + 1) : (i2 + 1)}</th>
                                                        <td style={{ padding: 2, textAlign: "center", verticalAlign: "middle" }}>{v2.branch_id}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.branch_name}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.location}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.contact_person}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.contact_email}</td>
                                                        <td style={{ padding: 2, textAlign: "left", verticalAlign: "middle" }}>{v2.contact_phone}</td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            <Button
                                                                onClick={() => setupedit(v2)}
                                                                size="small" variant='outlined' sx={{ height: 25, marginRight: 1, width: 60 }}
                                                                disabled={
                                                                    rules.length > 0
                                                                        ?
                                                                        (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                                            ?
                                                                            false
                                                                            :
                                                                            rules[0].Allow_Edit == 1
                                                                                ?
                                                                                false
                                                                                :
                                                                                true
                                                                        :
                                                                        true
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                            {/* <Button
                                                        color="error"
                                                        onClick={() => deletefunc(v2)}
                                                        size="small" variant='outlined' sx={{ height: 25, marginRight: 1, width: 60 }}
                                                    >
                                                        Delete
                                                    </Button> */}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </>
                                        })
                                        :
                                        branchList.map((v2, i2) => {
                                            return <>
                                                <tbody>
                                                    <tr key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                                        <th style={{ padding: 2, margin: 0, textAlign: "center", verticalAlign: "middle" }}>{(i2 + 1).toString().length < 2 ? "0" + (i2 + 1) : (i2 + 1)}</th>
                                                        <td style={{ padding: 2, margin: 0, textAlign: "center", verticalAlign: "middle" }}>{v2.branch_id}</td>
                                                        <td style={{ padding: 2, margin: 0, textAlign: "left", verticalAlign: "middle" }}>{v2.branch_name}</td>
                                                        <td style={{ padding: 2, margin: 0, textAlign: "left", verticalAlign: "middle" }}>{v2.location}</td>
                                                        <td style={{ padding: 2, margin: 0, textAlign: "left", verticalAlign: "middle" }}>{v2.contact_person}</td>
                                                        <td style={{ padding: 2, margin: 0, textAlign: "left", verticalAlign: "middle" }}>{v2.contact_email}</td>
                                                        <td style={{ padding: 2, margin: 0, textAlign: "left", verticalAlign: "middle" }}>{v2.contact_phone}</td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            <Button
                                                                onClick={() => setupedit(v2)}
                                                                size="small" variant='outlined' sx={{ height: 25, marginRight: 1, width: 60 }}
                                                                disabled={
                                                                    rules.length > 0
                                                                        ?
                                                                        (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                                            ?
                                                                            false
                                                                            :
                                                                            rules[0].Allow_Edit == 1
                                                                                ?
                                                                                false
                                                                                :
                                                                                true
                                                                        :
                                                                        true
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                            {/* <Button
                                                        color="error"
                                                        onClick={() => deletefunc(v2)}
                                                        size="small" variant='outlined' sx={{ height: 25, marginRight: 1, width: 60 }}
                                                    >
                                                        Delete
                                                    </Button> */}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </>
                                        })
                                }
                            </table>

                            <table
                                className="table"
                                id="table-main-small">
                                {
                                    filterArr.length
                                        ?
                                        filterArr.map((v1, i1) => {
                                            return <>
                                                <tbody key={i1 + 1} style={{ backgroundColor: ((i1 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Branch ID: {v1.branch_id}</td>
                                                        <td scope="col" id="mobile-style" style={{ textAlign: "right" }}>

                                                        </td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Branch Name: {v1.branch_name}</td>
                                                        <td scope="col" id="mobile-style" rowSpan={2} style={{ textAlign: "right" }}>
                                                            <Button
                                                                onClick={() => setupedit(v1)}
                                                                size="small" variant='outlined' sx={{ height: 25, width: 60, marginBottom: 1 }}
                                                                disabled={
                                                                    rules.length > 0
                                                                        ?
                                                                        (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                                            ?
                                                                            false
                                                                            :
                                                                            rules[0].Allow_Edit == 1
                                                                                ?
                                                                                false
                                                                                :
                                                                                true
                                                                        :
                                                                        true
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Location: {v1.location}</td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Contact Person: {v1.contact_person}</td>
                                                        <td scope="col" id="mobile-style" style={{ textAlign: "right" }}>
                                                            {/* <Button
                                                        color="error"
                                                        onClick={() => deletefunc(v1)}
                                                        size="small" variant='outlined' sx={{ height: 25, marginBottom: 1, width: 60 }}
                                                    >
                                                        Delete
                                                    </Button> */}
                                                        </td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style">Contact Email: {v1.contact_email}</td>
                                                        <td scope="col" rowSpan={2} id="mobile-style" style={{ pointerEvents: v1.mob ? v1.mob.toString().replace(/\s/g, '').length === 10 ? v1.whatsapp_status == "Y" ? "auto" : "auto" : "none" : "none" }}>

                                                        </td>
                                                    </tr>
                                                    <tr key={i1 + 1}>
                                                        <td scope="col" id="mobile-style" >Contact Phone: {v1.contact_phone}</td>
                                                    </tr>
                                                </tbody>
                                                <hr style={{ margin: 5, padding: 0 }} />
                                            </>
                                        })
                                        :
                                        branchList.map((v2, i2) => {
                                            return <>
                                                <tbody key={i2 + 1} style={{ backgroundColor: ((i2 + 1) % 2) !== 0 ? "#F5F5F5" : "white" }}>
                                                    <tr key={i2 + 1}>
                                                        <td scope="col" id="mobile-style" >Branch ID: {v2.branch_id}</td>
                                                        <td scope="col" id="mobile-style" style={{ textAlign: "right" }}>

                                                        </td>
                                                    </tr>
                                                    <tr key={i2 + 1}>
                                                        <td scope="col" id="mobile-style" >Branch Name: {v2.branch_name}</td>
                                                        <td scope="col" id="mobile-style" rowSpan={2} style={{ textAlign: "right" }}>
                                                            <Button
                                                                onClick={() => setupedit(v2)}
                                                                size="small" variant='outlined' sx={{ height: 25, width: 60, marginBottom: 1 }}
                                                                disabled={
                                                                    rules.length > 0
                                                                        ?
                                                                        (rules[0].User_Is_Super_Admin == 1 || rules[0].Allow_Admin == 1)
                                                                            ?
                                                                            false
                                                                            :
                                                                            rules[0].Allow_Edit == 1
                                                                                ?
                                                                                false
                                                                                :
                                                                                true
                                                                        :
                                                                        true
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                    <tr key={i2 + 1}>
                                                        <td scope="col" id="mobile-style" >Location: {v2.location}</td>
                                                    </tr>
                                                    <tr key={i2 + 1}>
                                                        <td scope="col" id="mobile-style" >Contact Person: {v2.contact_person}</td>
                                                        <td scope="col" id="mobile-style" style={{ textAlign: "right" }}>
                                                            {/* <Button
                                                        color="error"
                                                        onClick={() => deletefunc(v2)}
                                                        size="small" variant='outlined' sx={{ height: 25, marginBottom: 1, width: 60 }}
                                                    >
                                                        Delete
                                                    </Button> */}
                                                        </td>
                                                    </tr>
                                                    <tr key={i2 + 1}>
                                                        <td scope="col" id="mobile-style">Contact Email: {v2.contact_email}</td>
                                                        <td scope="col" id="mobile-style">

                                                        </td>
                                                    </tr>
                                                    <tr key={i2 + 1}>
                                                        <td scope="col" id="mobile-style" >Contact Phone: {v2.contact_phone}</td>
                                                    </tr>
                                                </tbody>
                                                <hr style={{ margin: 5, padding: 0 }} />
                                            </>
                                        })
                                }
                            </table>
                            <div>
                                <Dialog
                                    open={modalIsOpen1}
                                    onClose={closeModal1}
                                    PaperProps={{
                                        component: 'form',
                                        sx: {
                                            width: { xs: '100%', sm: '70%' },
                                        },
                                    }}
                                >
                                    <DialogTitle sx={{ fontSize: { xs: "2rem", md: "2.5rem" } }} >{`Add New Branch`}</DialogTitle>
                                    <DialogContent sx={{
                                        '& .MuiInputBase-input': { fontSize: '1.5rem' },
                                        '& .MuiInputLabel-root': { fontSize: '1.25rem' },
                                    }}>
                                        <Box display="flex" flexDirection="column" gap={2}>
                                            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                                                <TextField required autoFocus fullWidth variant="standard"
                                                    id="branch_name"
                                                    type="text"
                                                    name="branch_name"
                                                    label="Branch Name"
                                                    value={branchData.branch_name}
                                                    onChange={handleInputChange}

                                                // onBlur={handleBluremployeecode}
                                                // error={ErrorAlertOpenemployeecode}
                                                />
                                                <TextField required fullWidth variant="standard"
                                                    id="location"
                                                    type="text"
                                                    name="location"
                                                    label="Location"
                                                    value={branchData.location}
                                                    onChange={handleInputChange}
                                                // onBlur={handleBluremployeecode}
                                                // error={ErrorAlertOpenemployeecode}
                                                />
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                                                <TextField required fullWidth variant="standard"
                                                    id="contact_person"
                                                    type="text"
                                                    name="contact_person"
                                                    label="Contact Person"
                                                    value={branchData.contact_person}
                                                    onChange={handleInputChange}

                                                // onBlur={handleBluremployeecode}
                                                // error={ErrorAlertOpenemployeecode}
                                                />
                                                <TextField required fullWidth variant="standard"
                                                    id="contact_email"
                                                    type="text"
                                                    name="contact_email"
                                                    label="Branch Email"
                                                    value={branchData.contact_email}
                                                    onChange={handleInputChange}
                                                    onBlur={handleBluremail}
                                                    error={ErrorAlertOpenemail}
                                                />
                                            </Box>

                                            <MaskedTextField required fullWidth variant="standard"
                                                id="contact_phone"
                                                name="contact_phone"
                                                label="Branch Phone"
                                                type="text"
                                                value={branchData.contact_phone}
                                                onChange={handleInputChange}
                                                mask="0000-0000000"
                                                placeholder="0300-1234567"
                                                error={ErrorAlertOpenmobilenumber}
                                                onBlur={handleBlurmobilenumber}
                                            />
                                        </Box>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={closeModal1}
                                            disabled={buttonMessage !== '' ? true : false}
                                        >
                                            Cancel
                                        </Button>
                                        <Button type="submit"
                                            onClick={addBranch}
                                            disabled={buttonMessage !== '' ? true : false}
                                        >
                                            {buttonMessage !== '' ? buttonMessage : "Add"}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                            <div>
                                <Dialog
                                    open={modalIsOpen2}
                                    onClose={closeModal2}
                                    PaperProps={{
                                        component: 'form',
                                        sx: {
                                            width: { xs: '100%', sm: '70%' },
                                        },
                                    }}
                                >
                                    <DialogTitle sx={{ fontSize: { xs: "2rem", md: "2.5rem" } }} >{`Edit Branch`}</DialogTitle>
                                    <DialogContent sx={{
                                        '& .MuiInputBase-input': { fontSize: '1.5rem' },
                                        '& .MuiInputLabel-root': { fontSize: '1.25rem' },
                                    }}>
                                        <Box display="flex" flexDirection="column" gap={2}>
                                            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                                                <TextField required autoFocus fullWidth variant="standard"
                                                    id="branch_name"
                                                    type="text"
                                                    name="branch_name"
                                                    label="Branch Name"
                                                    value={editBranch.branch_name}
                                                    onChange={handledata}

                                                // onBlur={handleBluremployeecode}
                                                // error={ErrorAlertOpenemployeecode}
                                                />
                                                <TextField required fullWidth variant="standard"
                                                    id="location"
                                                    type="text"
                                                    name="location"
                                                    label="Location"
                                                    value={editBranch.location}
                                                    onChange={handledata}
                                                // onBlur={handleBluremployeecode}
                                                // error={ErrorAlertOpenemployeecode}
                                                />
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                                                <TextField required fullWidth variant="standard"
                                                    id="contact_person"
                                                    type="text"
                                                    name="contact_person"
                                                    label="Contact Person"
                                                    value={editBranch.contact_person}
                                                    onChange={handledata}

                                                // onBlur={handleBluremployeecode}
                                                // error={ErrorAlertOpenemployeecode}
                                                />
                                                <TextField required fullWidth variant="standard"
                                                    id="contact_email"
                                                    type="text"
                                                    name="contact_email"
                                                    label="Branch Email"
                                                    value={editBranch.contact_email}
                                                    onChange={handledata}
                                                // onBlur={handleBluremail}
                                                // error={ErrorAlertOpenemail}
                                                />
                                            </Box>
                                            <MaskedTextField required fullWidth variant="standard"
                                                id="contact_phone"
                                                name="contact_phone"
                                                label="Branch Phone"
                                                type="text"
                                                value={editBranch.contact_phone}
                                                onChange={handledata}
                                                mask="0000-0000000"
                                                placeholder="0300-1234567"
                                            // error={ErrorAlertOpenmobilenumber}
                                            // onBlur={handleBlurmobilenumber}
                                            />
                                        </Box>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={closeModal2}
                                            disabled={buttonMessage !== '' ? true : false}
                                        >
                                            Cancel
                                        </Button>
                                        <Button type="submit"
                                            onClick={editBranchSubmit}
                                            disabled={buttonMessage !== '' ? true : false}
                                        >
                                            {buttonMessage !== '' ? buttonMessage : "Submit"}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                            <Toaster />
                        </Box>
                        :
                        <div style={{ padding: 10 }}>View disabled</div>
                    :
                    ""
            }
        </>
    )
}

export default Branch