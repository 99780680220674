import React, { useState, useEffect, useMemo, useCallback } from 'react'
import axios from "axios"
// import NavigationBar from './navigation'
import Cookies from 'js-cookie'
import { AccountCircleOutlined } from '@mui/icons-material'
import DataTable from './DataTable'
import { MenuItem, Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import MaskedTextField from 'react-masked-mui-textfield'
import toast, { Toaster } from 'react-hot-toast'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import SalaryIncrementModal from './SalaryIncrementModal'
import { capitalizeValues } from '../utils/formatters'

const Employee = () => {

  const [modalIsOpen1, setIsOpen1] = useState(false)
  const [rules, setRules] = useState([])
  const [selectedEmployees, setSelectedEmployees] = useState([])
  const [employeeList, setEmployeeList] = useState([])
  const [incrementModalOpen, setIncrementModalOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [filterRows, setFilterRows] = useState([])

  const data = {
    company_id: Cookies.get('userInfo'),
    employeecode: "",
    employeecnic: "",
    employeename: "",
    employeeemail: "",
    guardianname: "",
    machineid: "",
    machineserial: "",
    userType_id: 1,
    dob: "",
    mobilenumber: "",
    residentialaddress: "",
    gender: "",
    martitalstatus: "",
    qualification: "",
    detailofqualification: "",
    doa: "",
    dop: "",
    designation: "",
    department: "",
    branch: "",
    ait: "",
    lia: "",
    aot: "",
    eoa: "",
    base_salary: "",
    incentive: "",
    conveyance: "",
    other_allowance: "",
    remarks: "",
    halfdayout: "",
    halfdayin: "",
    workinghours: "",
    employeebank: "",
    bankaccount: "",
    isActive: 1
  }

  const [inputData, setInputData] = useState(data)

  const [ErrorAlertOpenemployeecode, setErrorAlertOpenemployeecode] = useState(false)
  const [ErrorAlertOpenemployeecnic, setErrorAlertOpenemployeecnic] = useState(false)
  const [ErrorAlertOpenemployeename, setErrorAlertOpenemployeename] = useState(false)
  const [ErrorAlertOpenmachineid, setErrorAlertOpenmachineid] = useState(false)
  const [ErrorAlertOpenmachineserial, setErrorAlertOpenmachineserial] = useState(false)
  const [ErrorAlertOpendob, setErrorAlertOpendob] = useState(false)
  const [ErrorAlertOpengender, setErrorAlertOpengender] = useState(false)
  const [ErrorAlertOpenmobilenumber, setErrorAlertOpenmobilenumber] = useState(false)
  const [ErrorAlertOpenresidentialaddress, setErrorAlertOpenresidentialaddress] = useState(false)
  const [ErrorAlertOpenemployeeemail, setErrorAlertOpenemployeeemail] = useState(false)
  const [ErrorAlertOpendoa, setErrorAlertOpendoa] = useState(false)
  const [ErrorAlertOpendop, setErrorAlertOpendop] = useState(false)
  const [ErrorAlertOpenait, setErrorAlertOpenait] = useState(false)
  const [ErrorAlertOpenlia, setErrorAlertOpenlia] = useState(false)
  const [ErrorAlertOpenaot, setErrorAlertOpenaot] = useState(false)
  const [ErrorAlertOpeneoa, setErrorAlertOpeneoa] = useState(false)
  const [ErrorAlertOpenbase_salary, setErrorAlertOpenbase_salary] = useState(false)
  const [ErrorAlertOpenincentive, setErrorAlertOpenincentive] = useState(false)
  const [ErrorAlertOpenconveyance, setErrorAlertOpenconveyance] = useState(false)
  const [ErrorAlertOpenother_allowance, setErrorAlertOpenother_allowance] = useState(false)

  const [buttonMessage, setButtonMessage] = useState('')

  ////////////////////////////////
  function handleBluremployeecode() {
    if (inputData.employeecode < 1 || inputData.employeecode.length === "") {
      setErrorAlertOpenemployeecode(true)
    } else {
      setErrorAlertOpenemployeecode(false)
    }
  }

  function handleBluremployeecnic() {
    if (inputData.employeecnic.length < 15) {
      setErrorAlertOpenemployeecnic(true)
    } else {
      setErrorAlertOpenemployeecnic(false)
    }
  }

  function handleBluremployeename() {
    if (inputData.employeename.length < 1) {
      setErrorAlertOpenemployeename(true)
    } else {
      setErrorAlertOpenemployeename(false)
    }
  }

  function handleBlurmachineid() {
    if (inputData.machineid.length < 1) {
      setErrorAlertOpenmachineid(true)
    } else {
      setErrorAlertOpenmachineid(false)
    }
  }

  function handleBlurmachineserial() {
    if (inputData.machineserial.length < 1) {
      setErrorAlertOpenmachineserial(true)
    } else {
      setErrorAlertOpenmachineserial(false)
    }
  }

  function handleBlurdob() {
    if (inputData.dob === "" || inputData.dob.length < 10) {
      setErrorAlertOpendob(true)
    } else {
      setErrorAlertOpendob(false)
    }
  }

  function handleBlurgender() {
    if (inputData.gender.length === "") {
      setErrorAlertOpengender(true)
    } else {
      setErrorAlertOpengender(false)
    }
  }

  function handleBlurmobilenumber() {
    if (inputData.mobilenumber === "" || inputData.mobilenumber.length < 12) {
      setErrorAlertOpenmobilenumber(true)
    } else {
      setErrorAlertOpenmobilenumber(false)
    }
  }

  function handleBlurresidentialaddress() {
    if (inputData.residentialaddress.length < 1) {
      setErrorAlertOpenresidentialaddress(true)
    } else {
      setErrorAlertOpenresidentialaddress(false)
    }
  }

  function handleBluremployeeemail() {
    if (inputData.employeeemail.length < 1) {
      setErrorAlertOpenemployeeemail(true)
    } else {
      setErrorAlertOpenemployeeemail(false)
    }
  }

  function handleBlurdoa() {
    if (inputData.doa === "" || inputData.doa.length < 10) {
      setErrorAlertOpendoa(true)
    } else {
      setErrorAlertOpendoa(false)
    }
  }

  function handleBlurdop() {
    if (inputData.dop === "" || inputData.dop.length < 10) {
      setErrorAlertOpendop(true)
    } else {
      setErrorAlertOpendop(false)
    }
  }

  function handleBlurait() {
    if (inputData.ait === "" || inputData.ait.length < 5) {
      setErrorAlertOpenait(true)
    } else {
      setErrorAlertOpenait(false)
    }
  }

  function handleBlurlia() {
    if (inputData.lia === "" || inputData.lia.length < 5) {
      setErrorAlertOpenlia(true)
    } else {
      setErrorAlertOpenlia(false)
    }
  }

  function handleBluraot() {
    if (inputData.aot === "" || inputData.aot.length < 5) {
      setErrorAlertOpenaot(true)
    } else {
      setErrorAlertOpenaot(false)
    }
  }

  function handleBlureoa() {
    if (inputData.eoa === "" || inputData.eoa.length < 5) {
      setErrorAlertOpeneoa(true)
    } else {
      setErrorAlertOpeneoa(false)
    }
  }

  function handleBlurbase_salary() {
    if (inputData.base_salary === "" || inputData.base_salary < 1) {
      setErrorAlertOpenbase_salary(true)
    } else {
      setErrorAlertOpenbase_salary(false)
    }
  }

  function handleBlurincentive() {
    if (inputData.incentive === "" || inputData.incentive.length < 1) {
      setErrorAlertOpenincentive(true)
    } else {
      setErrorAlertOpenincentive(false)
    }
  }

  function handleBlurconveyance() {
    if (inputData.conveyance === "" || inputData.conveyance.length < 1) {
      setErrorAlertOpenconveyance(true)
    } else {
      setErrorAlertOpenconveyance(false)
    }
  }

  function handleBlurother_allowance() {
    if (inputData.other_allowance === "" || inputData.other_allowance.length < 1) {
      setErrorAlertOpenother_allowance(true)
    } else {
      setErrorAlertOpenother_allowance(false)
    }
  }

  //////////////////////////////////////////

  function handledata(e) {
    setInputData({ ...inputData, [e.target.name]: e.target.value })
  }

  function handledataIsActive(e) {
    if (e.target.checked) {
      setInputData({ ...inputData, [e.target.name]: 1 })
    }
    else {
      setInputData({ ...inputData, [e.target.name]: 0 })
    }
  }

  function handledataDept(e) {
    departmentList.map((v, i) => {
      if (v.department_id === e.target.value) {
        setInputData({ ...inputData, [e.target.name]: e.target.value, ait: v.inTime, lia: v.late, aot: v.outTime, eoa: v.early, halfdayin: v.halfdayin, halfdayout: v.halfdayout, workinghours: v.workinghours })
      }
    })
  }

  const addEmployee = async () => {
    try {
      setButtonMessage("Adding")
      const response = await axios.post('/api/addEmployee', {
        body: inputData
      })
      if (response.data.success) {
        setButtonMessage('')
        toast.success(response.data.message, {
          duration: 5000,
          position: 'bottom-right',
          style: {
            borderBottom: "3px solid #61D345",
            minWidth: '300px',
            minHeight: "50px"
          }
        })
        setInputData({
          ...inputData,
          employeecode: "",
          employeecnic: "",
          employeename: "",
          employeeemail: "",
          guardianname: "",
          // roll: "",
          dob: "",
          mobilenumber: "",
          residentialaddress: "",
          gender: "",
          martitalstatus: "",
          qualification: "",
          detailofqualification: "",
          machineid: "",
          machineserial: "",
          doa: "",
          dop: "",
          designation: "",
          department: "",
          branch: "",
          ait: "",
          lia: "",
          aot: "",
          eoa: "",
          remarks: "",
          userType_id: "",
          base_salary: "",
          incentive: "",
          conveyance: "",
          other_allowance: "",
          halfdayin: "",
          halfdayout: "",
          workinghours: "",
          employeebank: "",
          bankaccount: "",
          isActive: 1
        })
        fetchEmployeeList()
        closeModal1()
      } else {
        setButtonMessage('')
        if (response.data.error.code == "ER_DUP_ENTRY") {
          toast.error("Email already exists", {
            duration: 5000,
            position: 'bottom-right',
            style: {
              minWidth: '300px',
              minHeight: "50px"
            }
          })
        }
        else {
          toast.error(response.data.error.code, {
            duration: 5000,
            position: 'bottom-right',
            style: {
              minWidth: '300px',
              minHeight: "50px"
            }
          })
        }
        console.error('Failed to add employee: ', response.data.error)
      }
    } catch (error) {
      setButtonMessage('')
      console.error('Error adding employee: ', error)
    }
  }

  function handleSubmit(e) {
    e.preventDefault()
    if (!inputData.employeecode ||
      !inputData.employeecnic ||
      !inputData.employeename ||
      // !inputData.employeeemail ||
      !inputData.dob ||
      !inputData.mobilenumber ||
      !inputData.residentialaddress ||
      !inputData.gender ||
      !inputData.doa ||
      !inputData.base_salary ||
      !inputData.incentive ||
      !inputData.conveyance ||
      !inputData.designation ||
      !inputData.department ||
      !inputData.branch ||
      !inputData.other_allowance
    ) {
      toast.error("All fields are required", {
        duration: 5000,
        position: 'bottom-right',
        style: {
          minWidth: '300px',
          minHeight: "50px"
        }
      })
    } else {
      addEmployee()
    }
  }

  const openModal1 = () => {
    setIsOpen1(true)
  }

  const closeModal1 = () => {
    setIsOpen1(false)
  }

  const [departmentList, setDepartmentList] = useState([])
  const [designationList, setDesignationList] = useState([])
  const [branchList, setBranchList] = useState([])

  const fetchEmployeeList = async () => {
    try {
      const response = await axios.post('/api/fetchEmployeeList', {
        id: Cookies.get('userInfo')
      })
      if (response.data.response) {
        setEmployeeList(capitalizeValues(response.data.data))
      }
      else {
        console.error('Failed to fetch employee list: ', response.data.error)
      }
    } catch (error) {
      console.error('Error fetching employee list: ', error)
    }
  }

  const fetchRules = async () => {
    let id = Cookies.get('eid')
    let userInfo = Cookies.get('userInfo')
    if (id) {
      try {
        const response = await axios.post('/api/fetchRules', {
          id: id,
          module: "frm_Employee_List"
        })
        if (response.data.response) {
          setRules(response.data.data)
        }
        else {
          console.error('Failed to fetch rules: ', response.data.error)
        }
      } catch (error) {
        console.error('Error fetching rules: ', error)
      }
    }
    else {
      try {
        const response = await axios.post('/api/fetchRules', {
          userInfo: userInfo,
        })
        if (response.data.response) {
          setRules(response.data.data)
        }
        else {
          console.error('Failed to fetch rules: ', response.data.error)
        }
      } catch (error) {
        console.error('Error fetching rules: ', error)
      }
    }
  }

  const fetchDepartmentList = async () => {
    try {
      const response = await axios.post('/api/fetchDepartmentList', {
        id: Cookies.get('userInfo')
      })
      if (response.data.response) {
        setDepartmentList(response.data.data)
      }
      else {
        console.error('Failed to fetch department list: ', response.data.error)
      }
    } catch (error) {
      console.error('Error fetching department list: ', error)
    }
  }

  const fetchDesignationList = async () => {
    try {
      const response = await axios.post('/api/fetchDesignationList', {
        id: Cookies.get('userInfo')
      })
      if (response.data.response) {
        setDesignationList(response.data.data)
      }
      else {
        console.error('Failed to fetch designation list: ', response.data.error)
      }
    } catch (error) {
      console.error('Error fetching designation list: ', error)
    }
  }

  const fetchBranchList = async () => {
    try {
      const response = await axios.post('/api/fetchBranchList', {
        id: Cookies.get('userInfo')
      })
      if (response.data.response) {
        setBranchList(response.data.data)
      }
      else {
        console.error('Failed to fetch branch list: ', response.data.error)
      }
    } catch (error) {
      console.error('Error fetching branch list: ', error)
    }
  }

  useEffect(() => {
    fetchEmployeeList()
    fetchDepartmentList()
    fetchDesignationList()
    fetchBranchList()
    fetchRules()
  }, [])

  const columns = [
    "S. No", 'Emp ID', "Name", "Guardian Name", "Machine ID", "CNIC", "Phone", "Designation", "Department", "Branch", "Hired Date", "Status", "Action"
  ]

  // Memoize the selection handlers
  const handleSelectEmployee = useCallback((employeeId) => {
    setSelectedEmployees(prev => {
      if (prev.includes(employeeId)) {
        return prev.filter(id => id !== employeeId);
      } else {
        return [...prev, employeeId];
      }
    });
  }, []);

  const handleSelectAll = useCallback((event) => {
    if (event.target.checked) {
      const allEmployeeIds = employeeList.map(employee => employee.employee_id);
      setSelectedEmployees(allEmployeeIds);
    } else {
      setSelectedEmployees([]);
    }
  }, [employeeList]);

  // Memoize the employee selection props
  const employeeSelectionProps = useMemo(() => ({
    selectedEmployees,
    onSelectEmployee: handleSelectEmployee,
    onSelectAll: handleSelectAll,
  }), [selectedEmployees, handleSelectEmployee, handleSelectAll]);

  // Memoize the increment handler
  const handleIncrementClick = useCallback(() => {
    setIncrementModalOpen(true);
  }, []);

  // Memoize modal handlers
  const handleModalClose = useCallback(() => {
    setIncrementModalOpen(false);
  }, []);

  // Memoize modal props
  const modalProps = useMemo(() => ({
    open: incrementModalOpen,
    onClose: handleModalClose,
    selectedEmployees,
    onSuccess: fetchEmployeeList,
  }), [incrementModalOpen, handleModalClose, selectedEmployees, fetchEmployeeList]);

  // Helper function to safely convert to string and search
  const safeSearch = useCallback((value, searchTerm) => {
    if (value === null || value === undefined) return false;
    return String(value).toLowerCase().includes(searchTerm);
  }, []);

  // Memoize the search handler
  const handleSearch = useCallback((value) => {
    setSearchValue(value);
    if (!value.trim()) {
      setFilterRows([]);
      return;
    }

    const searchTerm = value.toLowerCase();
    const filtered = employeeList.filter(employee => 
      safeSearch(employee.employeename, searchTerm) ||
      safeSearch(employee.employeecode, searchTerm) ||
      safeSearch(employee.branch_name, searchTerm) ||
      safeSearch(employee.designation, searchTerm) ||
      safeSearch(employee.departmentName, searchTerm) ||
      safeSearch(employee.employeecnic, searchTerm) ||
      safeSearch(employee.mobilenumber, searchTerm)
    );

    setFilterRows(filtered);
  }, [employeeList, safeSearch]);

  const handleReset = useCallback(() => {
    setFilterRows([]);
    setSearchValue('');
  }, []);

  // Memoize all props passed to DataTable
  const dataTableProps = useMemo(() => ({
    columns,
    rows: employeeList,
    icon: <AccountCircleOutlined fontSize="large" />,
    title: "Employee",
    openModal1,
    fetchEmployeeList,
    rules,
    selectionProps: {
      selectedEmployees,
      onSelectEmployee: handleSelectEmployee,
      onSelectAll: handleSelectAll,
    },
    filterProps: {
      searchValue,
      filterRows,
      onSearch: handleSearch,
      onReset: handleReset,
    },
    onIncrementClick: handleIncrementClick,
  }), [
    employeeList,
    selectedEmployees,
    handleSelectEmployee,
    handleSelectAll,
    searchValue,
    filterRows,
    handleSearch,
    handleReset,
    handleIncrementClick,
  ]);

  return (
    <div>
      <DataTable {...dataTableProps} />
      <Dialog
        open={modalIsOpen1}
        // onClose={closeModal1}
        PaperProps={{
          component: 'form',
          sx: {
            width: { xs: '100%', sm: '70%' },
          },
        }}
      >
        <DialogTitle sx={{ fontSize: { xs: "2rem", md: "2.5rem" } }} >{`Add Employee`}</DialogTitle>
        <DialogContent sx={{
          '& .MuiInputBase-input': { fontSize: '1.5rem' },
          '& .MuiInputLabel-root': { fontSize: '1.25rem' },
        }}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField autoFocus required fullWidth variant="standard"
                id="employeecode"
                name="employeecode"
                label="Emloyee Code"
                type="number"
                value={inputData.employeecode}
                onChange={handledata}
                onBlur={handleBluremployeecode}
                error={ErrorAlertOpenemployeecode} />
              <MaskedTextField required fullWidth variant="standard"
                error={ErrorAlertOpenemployeecnic}
                id="employeecnic"
                name="employeecnic"
                label="Emloyee CNIC"
                type="text"
                value={inputData.employeecnic}
                onChange={handledata}
                mask="00000-0000000-0"
                placeholder="00000-0000000-0"
                onBlur={handleBluremployeecnic} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                error={ErrorAlertOpenemployeename}
                id="employeename"
                name="employeename"
                label="Emloyee Name"
                type="text"
                value={inputData.employeename}
                onChange={handledata}
                onBlur={handleBluremployeename} />
              <TextField fullWidth variant="standard"
                id="guardianname"
                name="guardianname"
                label="Guardian Name"
                type="text"
                value={inputData.guardianname}
                onChange={handledata} />
            </Box>
            {/* <TextField fullWidth variant="standard"
              id="roll"
              name="roll"
              label="Roll"
              type="text"
              value={inputData.roll}
              onChange={handledata} /> */}
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                error={ErrorAlertOpenmachineid}
                id="machineid"
                name="machineid"
                label="Machine ID"
                type="text"
                value={inputData.machineid}
                onChange={handledata}
                onBlur={handleBlurmachineid} />

              <TextField required fullWidth variant="standard"
                error={ErrorAlertOpenmachineserial}
                id="machineserial"
                name="machineserial"
                label="Machine Serial"
                type="text"
                value={inputData.machineserial}
                onChange={handledata}
                onBlur={handleBlurmachineserial} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="dob"
                name="dob"
                label="Date of Birth"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                       max: '2050-12-31',
                    min: '1900-01-01',
                    maxLength: 4, // Restrict input length to 4 digits
                  },
                }}
                value={inputData.dob}
                onChange={handledata}
                error={ErrorAlertOpendob}
                onBlur={handleBlurdob} />
              <MaskedTextField fullWidth required variant="standard"
                id="mobilenumber"
                name="mobilenumber"
                label="Mobile Number"
                type="text"
                value={inputData.mobilenumber}
                onChange={handledata}
                mask="0000-0000000"
                placeholder="0300-1234567"
                error={ErrorAlertOpenmobilenumber}
                onBlur={handleBlurmobilenumber} />
            </Box>
            <TextField required fullWidth variant="standard"
              id="residentialaddress"
              name="residentialaddress"
              label="Residential Address"
              type="text"
              value={inputData.residentialaddress}
              onChange={handledata}
              error={ErrorAlertOpenresidentialaddress}
              onBlur={handleBlurresidentialaddress} />
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
              <TextField required fullWidth variant="standard"
                id="employeeemail"
                name="employeeemail"
                label="Employee Email"
                type="text"
                value={inputData.employeeemail}
                onChange={handledata}
                // error={ErrorAlertOpenemployeeemail}
                // onBlur={handleBluremployeeemail}
                 />
              <FormControlLabel
                label="Active"
                control={
                  <Switch
                    id="isActive"
                    name="isActive"
                    onChange={handledataIsActive}
                    checked={inputData.isActive == 1 ? true : false}
                  />
                }
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="gender"
                name="gender"
                label="Gender"
                value={inputData.gender?.toLowerCase()}
                onChange={handledata}
                error={ErrorAlertOpengender}
                onClick={handleBlurgender}
                select>
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="transgender">Transgender</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </TextField>
              <TextField required fullWidth variant="standard"
                id="martitalstatus"
                name="martitalstatus"
                label="Marital Status"
                defaultValue={"unmarried"}
                value={inputData.martitalstatus?.toLowerCase()}
                onChange={handledata}
                select>
                <MenuItem value="married">Married</MenuItem>
                <MenuItem value="unmarried">Un Married</MenuItem>
                <MenuItem value="separated">Separated</MenuItem>
                <MenuItem value="widow">Widow</MenuItem>
                <MenuItem value="widower">Widower</MenuItem>
              </TextField>
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="qualification"
                name="qualification"
                label="Qualification"
                value={inputData.qualification?.toLowerCase()}
                onChange={handledata}
                select>
                <MenuItem value="middle">Middle</MenuItem>
                <MenuItem value="matric">Matric</MenuItem>
                <MenuItem value="intermediate">Intermediate</MenuItem>
                <MenuItem value="graduation">Graduation</MenuItem>
                <MenuItem value="master">Masters</MenuItem>
                <MenuItem value="phd">Phd</MenuItem>
                <MenuItem value="other">Others</MenuItem>
              </TextField>
              <TextField fullWidth variant="standard"
                id="detailofqualification"
                name="detailofqualification"
                label="Detail of Qualification"
                type="text"
                value={inputData.detailofqualification}
                onChange={handledata} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="doa"
                name="doa"
                label="Appointment Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                       max: '2050-12-31',
                    min: '1900-01-01',
                    maxLength: 4, // Restrict input length to 4 digits
                  },
                }}
                value={inputData.doa}
                onChange={handledata}
                error={ErrorAlertOpendoa}
                onBlur={handleBlurdoa} />
              <TextField required fullWidth variant="standard"
                id="dop"
                name="dop"
                label="Probation End Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                       max: '2050-12-31',
                    min: '1900-01-01',
                    maxLength: 4, // Restrict input length to 4 digits
                  },
                }}
                value={inputData.dop}
                onChange={handledata}
                error={ErrorAlertOpendop}
                onBlur={handleBlurdop} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
              <TextField required fullWidth variant="standard"
                id="designation"
                name="designation"
                label="Designation"
                value={inputData.designation}
                onChange={handledata}
                select>
                {/* <MenuItem value={"null"}>Select</MenuItem> */}
                {
                  designationList.map((v, i) => {
                    return <MenuItem value={v.designation_id}>{v.designation}</MenuItem>
                  })
                }
              </TextField>
              <TextField required fullWidth variant="standard"
                id="department"
                name="department"
                label="Department"
                value={inputData.department}
                onChange={handledataDept}
                select>
                {/* <MenuItem value={"null"}>Select</MenuItem> */}
                {
                  departmentList.map((v, i) => {
                    return <MenuItem value={v.department_id}>{v.departmentName}</MenuItem>
                  })
                }
              </TextField>
              <TextField required fullWidth variant="standard"
                id="branch"
                name="branch"
                label="Branch"
                value={inputData.branch}
                onChange={handledata}
                select>
                {/* <MenuItem value={"null"}>Select</MenuItem> */}
                {
                  branchList.map((v, i) => {
                    return <MenuItem value={v.branch_id}>{v.branch_name}</MenuItem>
                  })
                }
              </TextField>
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="ait"
                name="ait"
                label="Applied In Time"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={inputData.ait}
                onChange={handledata}
                error={ErrorAlertOpenait}
                onBlur={handleBlurait} />
              <TextField required fullWidth variant="standard"
                id="lia"
                name="lia"
                label="Late In Allowed"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={inputData.lia}
                onChange={handledata}
                error={ErrorAlertOpenlia}
                onBlur={handleBlurlia} />
              <TextField required fullWidth variant="standard"
                id="halfdayin"
                name="halfdayin"
                label="Half Day In Time:"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={inputData.halfdayin}
                onChange={handledata}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="aot"
                name="aot"
                label="Applied Out Time"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={inputData.aot}
                onChange={handledata}
                error={ErrorAlertOpenaot}
                onBlur={handleBluraot} />
              <TextField required fullWidth variant="standard"
                id="eoa"
                name="eoa"
                label="Early Out Allowed"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={inputData.eoa}
                onChange={handledata}
                error={ErrorAlertOpeneoa}
                onBlur={handleBlureoa} />
              <TextField required fullWidth variant="standard"
                id="halfdayout"
                name="halfdayout"
                label="Half Day Out Time:"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={inputData.halfdayout}
                onChange={handledata}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="employeebank"
                name="employeebank"
                label="Bank"
                type="text"
                value={inputData.employeebank}
                onChange={handledata}
              // error={ErrorAlertOpenconveyance}
              // onBlur={handleBlurconveyance} 
              />
              <TextField required fullWidth variant="standard"
                id="bankaccount"
                name="bankaccount"
                label="Account"
                type="number"
                value={inputData.bankaccount}
                onChange={handledata}
              // error={ErrorAlertOpenother_allowance}
              // onBlur={handleBlurother_allowance}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="base_salary"
                name="base_salary"
                label="Base Salary"
                type="number"
                value={inputData.base_salary}
                onChange={handledata}
                error={ErrorAlertOpenbase_salary}
                onBlur={handleBlurbase_salary} />
              <TextField required fullWidth variant="standard"
                id="incentive"
                name="incentive"
                label="Incentive allowance"
                type="number"
                value={inputData.incentive}
                onChange={handledata}
                error={ErrorAlertOpenincentive}
                onBlur={handleBlurincentive} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="conveyance"
                name="conveyance"
                label="Conveyance allowance"
                type="number"
                value={inputData.conveyance}
                onChange={handledata}
                error={ErrorAlertOpenconveyance}
                onBlur={handleBlurconveyance} />
              <TextField required fullWidth variant="standard"
                id="other_allowance"
                name="other_allowance"
                label="Other allowance"
                type="number"
                value={inputData.other_allowance}
                onChange={handledata}
                error={ErrorAlertOpenother_allowance}
                onBlur={handleBlurother_allowance} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }} >
              <TextField required fullWidth variant="standard"
                id="workinghours"
                name="workinghours"
                label="Working Hours:"
                value={inputData.workinghours}
                onChange={handledata}
                select
              >
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="6">6</MenuItem>
                <MenuItem value="7">7</MenuItem>
                <MenuItem value="8">8</MenuItem>
                <MenuItem value="9">9</MenuItem>
                <MenuItem value="10">10</MenuItem>
                <MenuItem value="11">11</MenuItem>
                <MenuItem value="12">12</MenuItem>
              </TextField>
              <TextField fullWidth variant="standard"
                id="remarks"
                name="remarks"
                label="Remarks & Other Notes"
                type="text"
                // multiline
                // rows={4}
                value={inputData.remarks}
                onChange={handledata} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeModal1}
            disabled={buttonMessage !== '' ? true : false}
          >
            Cancel
          </Button>
          <Button type="submit"
            onClick={handleSubmit}
            disabled={buttonMessage !== '' ? true : false}
          >
            {buttonMessage !== '' ? buttonMessage : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
      <SalaryIncrementModal {...modalProps} />
      <Toaster />
    </div >
  )
}

export default React.memo(Employee)